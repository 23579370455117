import React, { useRef, useState, useEffect } from "react";
import MapLayout from "../../Layouts/MapLayout";
import { CalendarIcon, MapPinIcon, UsersIcon } from "@heroicons/react/20/solid";
import mapboxgl from "!mapbox-gl";

const Map = ({ jobs, location }) => {
  mapboxgl.accessToken =
    "pk.eyJ1Ijoic2ltb253aWxsaSIsImEiOiJjbDUyaGlwNG0wZm8yM2tteTNxd3M5NDNnIn0.JKbWNXiEZJe9Cdm9J8BVSg";

  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return;

    let bounds = [
      [-180, -80],
      [180, 80],
    ];

    let lng;
    let lat;

    if (location) {
      lng = location.longitude;
      lat = location.latitude;
    } else {
      lng = -0.12152066549504878;
      lat = 51.500462822124;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: 8,
      maxBounds: bounds,
      maxBoundsViscosity: 1,
      scrollZoom: true // Enable scroll zooming
    });

    // map.getBounds(),
    // map.current.scrollZoom.disable();
    map.current.addControl(new mapboxgl.NavigationControl());

    let geojson = prepareGeoJson(jobs);

    map.current.on("load", () => {
      // Add a new source from our GeoJSON data and
      // set the 'cluster' option to true. GL-JS will
      // add the point_count property to your source data.
      map.current.addSource("jobs", {
        type: "geojson",
        // Point to GeoJSON data. This example visualizes all M1.0+ jobs
        // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
        data: geojson,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      });

      map.current.addLayer({
        id: "clusters",
        type: "circle",
        source: "jobs",
        filter: ["has", "point_count"],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#51bbd6",
            100,
            "#f1f075",
            750,
            "#f28cb1",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      map.current.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "jobs",
        filter: ["has", "point_count"],
        layout: {
          "text-field": ["get", "point_count_abbreviated"],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      map.current.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "jobs",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 10,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      // inspect a cluster on click
      map.current.on("click", "clusters", (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        map.current
          .getSource("jobs")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      // When a click event occurs on a feature in
      // the unclustered-point layer, open a popup at
      // the location of the feature, with
      // description HTML from its properties.
      map.current.on("click", "unclustered-point", (e) => {
        const job_id = e.features[0].properties.id;

        window.location.href = "/training/" + job_id;
      });

      map.current.on("mouseenter", "clusters", () => {
        map.current.getCanvas().style.cursor = "pointer";
      });
      map.current.on("mouseleave", "clusters", () => {
        map.current.getCanvas().style.cursor = "";
      });
    });
  }, []);

  const prepareGeoJson = (p) => {
    let f = [];

    p.map((pin) => {
      if (pin.user.location) {
        f.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              pin.user.location.longitude,
              pin.user.location.latitude,
            ],
          },
          properties: {
            id: pin.id,
          },
        });
      }
    });

    return {
      type: "FeatureCollection",
      features: f,
    };
  };

  return (
    <>
      <div className="h-screen" ref={mapContainer}></div>
    </>
  );
};

Map.layout = (page) => <MapLayout children={page} />;

export default Map;
