import React, { useState } from "react";
import Layout from "../../Layouts/Layout";
import General from "../../Components/Profile/General";
import Subscription from "../../Components/Profile/Subscription";
import JobSeeker from "../../Components/Profile/JobSeeker";
import Recruiter from "../../Components/Profile/Recruiter";
import { usePage } from "@inertiajs/inertia-react";
import { Training } from "../../Components/Profile/Training";
import { Contractor } from "../../Components/Profile/Contractor";

const Profile = ({
  general,
  locations,
  recruiter,
  job_seeker,
  param,
  trades,
  qualifications,
  job_seeker_qualifications,
  contractor,
  training,
  training_qualifications,
}) => {
  const [section, setSection] = useState(param ? param : "general");

  const { user } = usePage().props;

  const tabs =
    user.main_account_type == "recruiter"
      ? [
          {
            name: "User Information",
            selector: "general",
            current: section == "general",
          },
          {
            name: "Recruiter",
            selector: "recruiter",
            current: section == "recruiter",
          },
          {
            name: "Subscriptions",
            selector: "subscriptions",
            current: section == "subscriptions",
          },
        ]
      : user.main_account_type == "job_seeker"
      ? [
          {
            name: "User Information",
            selector: "general",
            current: section == "general",
          },
          {
            name: "Job Seeker",
            selector: "job_seeker",
            current: section == "job_seeker",
          },
        ]
      : user.main_account_type == "training"
      ? [
          {
            name: "User Information",
            selector: "general",
            current: section == "general",
          },
          {
            name: "Subscriptions",
            selector: "subscriptions",
            current: section == "subscriptions",
          },
          {
            name: "Subscriptions",
            selector: "subscriptions",
            current: section == "subscriptions",
          },
        ]
      : user.main_account_type == "contractor"
      ? [
          {
            name: "User Information",
            selector: "general",
            current: section == "general",
          },
          {
            name: "Contractor",
            selector: "contractor",
            current: section == "contractor",
          },
          {
            name: "Subscriptions",
            selector: "subscriptions",
            current: section == "subscriptions",
          },
        ]
      : [
          {
            name: "User Information",
            selector: "general",
            current: section == "general",
          },
        ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const SectionSwitch = () => {
    switch (section) {
      case "general":
        return <General general={general} locations={locations} />;
      case "job_seeker":
        return (
          <JobSeeker
            job_seeker={job_seeker}
            user={user}
            qualifications={qualifications}
            trades={trades}
            job_seeker_qualifications={job_seeker_qualifications}
          />
        );
      case "recruiter":
        return <Recruiter recruiter={recruiter} user={user} />;
      case "training":
        return (
          <Training
            user={user}
            qualifications={qualifications}
            training={training}
            training_qualifications={training_qualifications}
          />
        );
      case "contractor":
        return <Contractor user={user} contractor={contractor} />;
      case "accommodation":
        return <General general={general} />;
      case "subscriptions":
        if (general?.main_account_type != "job_seeker") {
          return <Subscription general={general} />;
        }
      default:
        break;
    }
  };

  return (
    <>
      <main className="flex-1">
        <div className="relative mx-auto max-w-7xl">
          <div className="pb-16">
            <div className="px-4 sm:px-6 md:px-0">
              <div className="py-6">
                {/* Tabs */}
                <div className="lg:hidden">
                  <label htmlFor="selected-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="selected-tab"
                    name="selected-tab"
                    onChange={(e) => setSection(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                    defaultValue={tabs.find((tab) => tab.current).name}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name} value={tab.selector}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden lg:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8">
                      {tabs.map((tab) => (
                        <button
                          key={tab.name}
                          onClick={() => setSection(tab.selector)}
                          className={classNames(
                            tab.current
                              ? "border-orange-500 text-orange-600"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                          )}
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>
                {SectionSwitch()}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
Profile.layout = (page) => <Layout children={page} title="Profile" />;

export default Profile;
