import React, { Fragment, useRef, useState } from "react";
import { useForm } from "@inertiajs/inertia-react";

export const Contractor = ({ user, contractor }) => {
  const { data, setData, post, processing, progress, errors, reset } = useForm({
    id: contractor ? contractor.id : null,
    user_id: user ? user.id : null,
    contact_number: contractor ? contractor.contact_number : null,
    description: contractor ? contractor.description : null,
    website: contractor ? contractor.website : null,
    company_name: contractor ? contractor.company_name : null,
  });

  function submit(e) {
    e.preventDefault();
    post("/profile/contractor");
  }

  // const prepareFile = (e) => {
  //   const objectUrl = URL.createObjectURL(e.target.files[0]);
  //   setData({
  //     ...data,
  //     image: e.target.files[0],
  //     inputed_file: objectUrl,
  //   });
  //   // setData("image", e.target.files[0]);
  // };

  return (
    <>
      <form onSubmit={submit} className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Contractor
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            This is where you can update your contractor profile.
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <label
                htmlFor="name"
                className="text-sm font-medium text-gray-500"
              >
                Company Name
              </label>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setData("company_name", e.target.value)}
                  value={data.company_name}
                  autoComplete="name"
                  className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                />
                {errors.company_name && <div>{errors.company_name}</div>}
              </div>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <label
                htmlFor="description"
                className="text-sm font-medium text-gray-500"
              >
                Description
              </label>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <textarea
                  type="text"
                  name="description"
                  id="description"
                  onChange={(e) => setData("description", e.target.value)}
                  autoComplete="description"
                  value={data.description}
                  className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                ></textarea>
                {errors.description && <div>{errors.description}</div>}
              </div>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <label
                htmlFor="phone_number"
                className="text-sm font-medium text-gray-500"
              >
                Phone Number
              </label>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="tel"
                  name="phone_number"
                  id="phone_number"
                  onChange={(e) => setData("contact_number", e.target.value)}
                  value={data.contact_number}
                  autoComplete="phone_number"
                  className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                />
                {errors.contact_number && <div>{errors.contact_number}</div>}
              </div>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <label
                htmlFor="website"
                className="text-sm font-medium text-gray-500"
              >
                Website
              </label>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  name="website"
                  id="website"
                  onChange={(e) => setData("website", e.target.value)}
                  value={data.website}
                  autoComplete="website"
                  className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                />
                {errors.website && <div>{errors.website}</div>}
              </div>
            </div>
          </dl>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              // disabled={processing}
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Contractor;
