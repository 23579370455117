import { useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../Components/Loader";
import Swal from "sweetalert2";

const CheckoutForm = ({
  setOpen,
  setPaymentSuccessModal,
  isCardList,
  selectedPlanName,
  priceId,
  userId,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [selectedCard, setSelectedCard] = useState();
  const [listCards, setListCards] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setloading] = useState(false);
  const [payLoading, setPayLoading] = useState(false);

  useEffect(() => {
    setloading(true);
    axios
      .get("/subscriptions/listcard", {
        params: { userId: userId },
      })
      .then((response) => {
        setloading(false);
        setListCards(response?.data?.cards);

        setSelectedCard(response.data.cards.find((c) => c.is_default));
      })
      .catch((error) => {
        setloading(false);
        Swal.fire("Error", error.message, "error");
      });
  }, [isCardList]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    if (!selectedCard) {
      if (listCards?.length === 0) {
        setErrorMessage("");
      } else {
        setErrorMessage("Please select a card.");
      }

      setloading(false);
      setPayLoading(false);
      return;
    } else {
      axios
        .post("/subscriptions/updatecard", {
          paymentId: selectedCard.card.id,
          cardId: selectedCard.id,
        })
        .then((res) => {
          if (res?.status === 200 && res?.data?.status) {
            console.log(res.data);
            setOpen(false);
            Swal.fire({
              icon: "success",
              title: "Card Changed",
              text: "Thank you for updating yor details!",
            });
            setloading(false);
            // stripe
            //   .confirmCardPayment(res?.data?.data, {
            //     payment_method: selectedCard?.card?.id,
            //   })
            //   .then((resPayment) => {
            //     if (resPayment?.paymentIntent?.id) {
            //       setOpen(false);
            //       setPaymentSuccessModal(true);
            //       setloading(false);

            //     } else {
            //       setErrorMessage(resPayment.message);
            //       setloading(false);
            //     }
            //   }).catch((error) => {
            //     setloading(false)
            //     setErrorMessage(error)
            //     Swal.fire('Error', error.message, 'error');
            //   });
          } else {
            setloading(false);
            Swal.fire("Error", res?.data?.message, "error");
            setOpen(false);
          }
        })
        .catch((error) => {
          setloading(false);
          Swal.fire("Error", "Something went wrong!!", "error");
        });
    }
  };

  return (
    <>
      <div className="w-full text-white mb-4">
        {loading && (
          <div className="py-4">
            <Loader />
          </div>
        )}
        {listCards?.length === 0 && !loading && (
          <div className="mt-2">
            <p className="sm:text-lg text-sm text-gray-500 flex justify-center">
              No Card Available, Please add card
            </p>
          </div>
        )}

        {listCards?.map((item, index) => (
          <button
            onClick={() => {
              setSelectedCard(item);
            }}
            key={index}
            className={`${
              item === selectedCard ? `bg-orange-300` : `bg-transparent`
            } text-black mt-3 flex justify-between p-3 border-2  text-base font-thin rounded-lg w-full`}
          >
            <div>{`**** **** **** ${item?.card?.card?.last4}`}</div>
            <div className="flex my-auto">
              <div>{`${item?.card?.card?.brand?.toUpperCase()}`}</div>
              <div className={`w-5 h-5 rounded-full bg-black my-auto ml-5`}>
                <div
                  className={`${
                    item === selectedCard ? `bg-themecolor` : `bg-slate-300`
                  } w-3 h-3 m-1 flex justify-center rounded-full`}
                ></div>
              </div>
            </div>
          </button>
        ))}
      </div>
      <p className="text-red-500 mt-2">{errorMessage}</p>
      <div
        className={`${
          listCards?.length === 0 ? "" : "sm:grid"
        } sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 mt-4`}
      >
        {listCards?.length === 0 ? null : (
          <button
            type="submit"
            // className={`${!stripe || !elements || loading || !selectedCard ? 'cursor-not-allowed' : ''} items-center inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
            // disabled={!stripe || !elements || loading || !selectedCard}
            className={`items-center inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
            onClick={() => {
              setErrorMessage("");
              setloading(true);
              setPayLoading(true);
              handleSubmit();
            }}
          >
            {payLoading ? <Loader /> : `Select Card`}
          </button>
        )}
        <button
          type="button"
          className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export default CheckoutForm;
