import React, { Fragment, useRef, useState } from "react";
import {
  BuildingOffice2Icon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useForm } from "@inertiajs/inertia-react";

export const Recruiter = ({ recruiter, user }) => {
  const fileInput = useRef();

  const { data, setData, post, processing, progress, errors, reset } = useForm({
    id: recruiter ? recruiter.id : null,
    user_id: user.id,
    organization: recruiter ? recruiter.organization : null,
    contact_number: recruiter ? recruiter.contact_number : null,
    about: recruiter ? recruiter.about : null,
    website: recruiter ? recruiter.website : null,
    image: {},
    inputed_file:
      recruiter && recruiter.image ? recruiter.image.width_450.url : null,
  });

  function submit(e) {
    e.preventDefault();
    post("/profile/recruiter");
  }

  const prepareFile = (e) => {
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setData({
      ...data,
      image: e.target.files[0],
      inputed_file: objectUrl,
    });
    // setData("image", e.target.files[0]);
  };

  return (
    <>
      <form onSubmit={submit} className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Recruiter
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            This is where you can update your recruiter profile.
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <label
                htmlFor="name"
                className="text-sm font-medium text-gray-500"
              >
                Company Name
              </label>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setData("organization", e.target.value)}
                  value={data.organization}
                  autoComplete="name"
                  className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                />
                {errors.organization && <div>{errors.organization}</div>}
              </div>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <label
                htmlFor="description"
                className="text-sm font-medium text-gray-500"
              >
                Description
              </label>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <textarea
                  type="text"
                  name="description"
                  id="description"
                  onChange={(e) => setData("about", e.target.value)}
                  autoComplete="description"
                  className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                >
                  {data.about}
                </textarea>
                {errors.about && <div>{errors.about}</div>}
              </div>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <dt className="text-sm font-medium text-gray-500">
                Company Logo
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {progress ? (
                  <progress value={progress.percentage} max="100">
                    {progress.percentage}%
                  </progress>
                ) : (
                  <span className="flex-grow">
                    {data.inputed_file ? (
                      <img
                        className="h-24 w-24 object-cover rounded"
                        src={data.inputed_file}
                        alt={data.name + " Logo"}
                      />
                    ) : (
                      <BuildingOffice2Icon
                        className="h-9 w-9 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                )}
                <span className="ml-4 flex flex-shrink-0 items-center space-x-4">
                  <button
                    type="button"
                    onClick={() => fileInput.current.click()}
                    className="rounded-md bg-white font-medium text-orange-600 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                  >
                    Update
                  </button>

                  <input
                    type="file"
                    className="hidden"
                    value={data.file}
                    ref={fileInput}
                    onChange={prepareFile}
                  />

                  {data.image && (
                    <>
                      <span className="text-gray-300" aria-hidden="true">
                        |
                      </span>
                      <button
                        type="button"
                        onClick={() =>
                          setData({ ...data, image: "", inputed_file: "" })
                        }
                        className="rounded-md bg-white font-medium text-orange-600 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                      >
                        Remove
                      </button>
                    </>
                  )}
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <label
                htmlFor="phone_number"
                className="text-sm font-medium text-gray-500"
              >
                Phone Number
              </label>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="tel"
                  name="phone_number"
                  id="phone_number"
                  onChange={(e) => setData("contact_number", e.target.value)}
                  value={data.contact_number}
                  autoComplete="phone_number"
                  className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                />
                {errors.contact_number && <div>{errors.contact_number}</div>}
              </div>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <label
                htmlFor="website"
                className="text-sm font-medium text-gray-500"
              >
                Website
              </label>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  name="website"
                  id="website"
                  onChange={(e) => setData("website", e.target.value)}
                  value={data.website}
                  autoComplete="website"
                  className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                />
                {errors.website && <div>{errors.website}</div>}
              </div>
            </div>
          </dl>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={processing}
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Recruiter;
