import React, { Fragment, useMemo, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { RadioGroup } from "@headlessui/react";
import { useForm } from "@inertiajs/inertia-react";
import LocationComponent from "../LocationComponent";
import {
  ArrowUpTrayIcon,
  BuildingOffice2Icon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import Select from "react-select";
import { SelectStyles } from "../../styles/ReactSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export const JobSeeker = ({
  job_seeker,
  user,
  qualifications,
  trades,
  job_seeker_qualifications,
}) => {
  const { data, setData, post, processing, progress, errors, reset } = useForm({
    id: job_seeker ? job_seeker.id : "",
    user_id: user.id,
    resume_picture: job_seeker ? job_seeker.resume_picture : "",
    contact_phone: job_seeker ? job_seeker.contact_phone : "",
    about: job_seeker ? job_seeker.about : "",
    date_of_birth: job_seeker ? job_seeker.date_of_birth : "",
    job_one_role: job_seeker ? job_seeker.job_one_role : "",
    job_one_description: job_seeker ? job_seeker.job_one_description : "",
    job_one_years_experience: job_seeker
      ? job_seeker.job_one_years_experience
      : "",
    job_two_role: job_seeker ? job_seeker.job_two_role : "",
    job_two_description: job_seeker ? job_seeker.job_two_description : "",
    job_two_years_experience: job_seeker
      ? job_seeker.job_two_years_experience
      : "",
    job_three_role: job_seeker ? job_seeker.job_three_role : "",
    job_three_description: job_seeker ? job_seeker.job_three_description : "",
    job_three_years_experience: job_seeker
      ? job_seeker.job_three_years_experience
      : "",
    trade_ids:
      job_seeker && job_seeker.trades && job_seeker.trades.length
        ? job_seeker.trades
        : "",
    qualification_ids:
      // job_seeker &&
      // job_seeker.qualifications &&
      // job_seeker.qualifications.length
      //   ? job_seeker.qualifications
      //   : [],
      job_seeker_qualifications,
  });

  const cvInput = useRef();

  const fileInput = useMemo(() => {
    const refs = {};
    data.qualification_ids.forEach((item) => {
      refs[item.id] = React.createRef(null);
    });
    return refs;
  }, [data.qualification_ids]);

  function submit(e) {
    e.preventDefault();
    post("/profile/job_seeker", data, {
      forceFormData: true,
    });
  }

  const setExpiryDate = (date, qualification_id) => {
    let qualifications = data.qualification_ids;
    qualifications.map((q) => {
      if (q.id == qualification_id) {
        q.expiry_date = date;
      }
    });

    setData({ ...data, qualification_ids: qualifications });
  };

  const prepareFile = (qualification_id, file) => {
    const objectUrl = URL.createObjectURL(file);
    let qualifications = data.qualification_ids;
    qualifications.map((q) => {
      if (q.id == qualification_id) {
        q.path = file;
        q.temp_path = objectUrl;
      }
    });
    setData({ ...data, qualification_ids: qualifications });
  };

  return (
    <>
      <form onSubmit={submit} className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Job Seeker
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            This is all your information for applying for work.
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <label
                htmlFor="description"
                className="text-sm font-medium text-gray-500"
              >
                About yourself
              </label>
              <div className="flex max-w-2xl rounded-md shadow-sm">
                <textarea
                  type="text"
                  name="description"
                  id="description"
                  onChange={(e) => setData("about", e.target.value)}
                  autoComplete="description"
                  value={data.about}
                  className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                ></textarea>
                {errors.about && <div>{errors.about}</div>}
              </div>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <label
                htmlFor="phone_number"
                className="text-sm font-medium text-gray-500"
              >
                Phone Number
              </label>
              <div className="flex max-w-2xl rounded-md shadow-sm">
                <input
                  type="tel"
                  name="phone_number"
                  id="phone_number"
                  onChange={(e) => setData("contact_phone", e.target.value)}
                  value={data.contact_phone}
                  autoComplete="phone_number"
                  className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                />
                {errors.contact_phone && <div>{errors.contact_phone}</div>}
              </div>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <label
                htmlFor="date_of_birth"
                className="text-sm font-medium text-gray-500"
              >
                Date of birth
              </label>
              <div className="flex max-w-2xl rounded-md shadow-sm">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                  selected={
                    data.date_of_birth
                      ? moment(data.date_of_birth).toDate()
                      : null
                  }
                  onChange={(date) => setData("date_of_birth", date)}
                />
                {errors.date_of_birth && <div>{errors.date_of_birth}</div>}
              </div>
            </div>

            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <label
                htmlFor="trades"
                className="text-sm font-medium text-gray-500"
              >
                Trade
              </label>
              <div className="max-w-2xl">
                <Select
                  options={trades}
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ name }) => name}
                  styles={SelectStyles}
                  value={data.trade_ids ? data.trade_ids[0] : null}
                  onChange={(e) => {
                    setData("trade_ids", [e]);
                  }}
                />
                {errors.trade_ids && <div>{errors.trade_ids}</div>}
              </div>
            </div>
          </dl>
        </div>
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mt-6">
            Experience
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            This is where you can show your previous three jobs experience
          </p>
        </div>
        <div className="mt-6">
          <h4 className="mt-4 font-semibold text-gray-700">Job One</h4>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
            <label
              htmlFor="job_one_role"
              className="text-sm font-medium text-gray-500"
            >
              Job Role
            </label>
            <div className="flex max-w-2xl rounded-md shadow-sm">
              <input
                type="text"
                name="job_one_role"
                id="job_one_role"
                onChange={(e) => setData("job_one_role", e.target.value)}
                value={data.job_one_role}
                autoComplete="job_one_role"
                className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
              />
              {errors.job_one_role && <div>{errors.job_one_role}</div>}
            </div>
          </div>

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <label
              htmlFor="job_one_description"
              className="text-sm font-medium text-gray-500"
            >
              Job Description
            </label>
            <div className="flex max-w-2xl rounded-md shadow-sm">
              <textarea
                type="text"
                name="job_one_description"
                id="job_one_description"
                onChange={(e) => setData("job_one_description", e.target.value)}
                autoComplete="job_one_description"
                value={data.job_one_description}
                className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
              ></textarea>
              {errors.job_one_description && (
                <div>{errors.job_one_description}</div>
              )}
            </div>
          </div>

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
            <label
              htmlFor="date_of_birth"
              className="text-sm font-medium text-gray-500"
            >
              How long did you work here?
            </label>
            <div className="flex max-w-2xl rounded-md shadow-sm">
              <input
                type="text"
                name="job_one_years_experience"
                id="job_one_years_experience"
                onChange={(e) =>
                  setData("job_one_years_experience", e.target.value)
                }
                value={data.job_one_years_experience}
                autoComplete="job_one_years_experience"
                className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
              />
              {errors.job_one_years_experience && (
                <div>{errors.job_one_years_experience}</div>
              )}
            </div>
          </div>
          <h4 className="pt-4 font-semibold text-gray-700 border-t border-gray-200">
            Job Two
          </h4>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5 ">
            <label
              htmlFor="phone_number"
              className="text-sm font-medium text-gray-500"
            >
              Job Role
            </label>
            <div className="flex max-w-2xl rounded-md shadow-sm">
              <input
                type="text"
                name="job_two_role"
                id="job_two_role"
                onChange={(e) => setData("job_two_role", e.target.value)}
                value={data.job_two_role}
                autoComplete="job_two_role"
                className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
              />
              {errors.job_two_role && <div>{errors.job_two_role}</div>}
            </div>
          </div>

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <label
              htmlFor="description"
              className="text-sm font-medium text-gray-500"
            >
              Job Description
            </label>
            <div className="flex max-w-2xl rounded-md shadow-sm">
              <textarea
                type="text"
                name="job_two_description"
                id="job_two_description"
                onChange={(e) => setData("job_two_description", e.target.value)}
                autoComplete="job_two_description"
                value={data.job_two_description}
                className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
              ></textarea>
              {errors.job_two_description && (
                <div>{errors.job_two_description}</div>
              )}
            </div>
          </div>

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
            <label
              htmlFor="date_of_birth"
              className="text-sm font-medium text-gray-500"
            >
              How long did you work here?
            </label>
            <div className="flex max-w-2xl rounded-md shadow-sm">
              <input
                type="text"
                name="job_two_years_experience"
                id="job_two_years_experience"
                onChange={(e) =>
                  setData("job_two_years_experience", e.target.value)
                }
                value={data.job_two_years_experience}
                autoComplete="job_two_years_experience"
                className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
              />
              {errors.job_two_years_experience && (
                <div>{errors.job_two_years_experience}</div>
              )}
            </div>
          </div>

          <h4 className="pt-4 font-semibold text-gray-700 border-t border-gray-200">
            Job Three
          </h4>

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
            <label
              htmlFor="phone_number"
              className="text-sm font-medium text-gray-500"
            >
              Job Role
            </label>
            <div className="flex max-w-2xl rounded-md shadow-sm">
              <input
                type="text"
                name="job_three_role"
                id="job_three_role"
                onChange={(e) => setData("job_three_role", e.target.value)}
                value={data.job_three_role}
                autoComplete="job_three_role"
                className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
              />
              {errors.job_three_role && <div>{errors.job_three_role}</div>}
            </div>
          </div>

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <label
              htmlFor="description"
              className="text-sm font-medium text-gray-500"
            >
              Job Description
            </label>
            <div className="flex max-w-2xl rounded-md shadow-sm">
              <textarea
                type="text"
                name="job_three_description"
                id="job_three_description"
                onChange={(e) =>
                  setData("job_three_description", e.target.value)
                }
                autoComplete="job_three_description"
                value={data.job_three_description}
                className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
              ></textarea>
              {errors.job_three_description && (
                <div>{errors.job_three_description}</div>
              )}
            </div>
          </div>

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
            <label
              htmlFor="date_of_birth"
              className="text-sm font-medium text-gray-500"
            >
              How long did you work here?
            </label>
            <div className="flex max-w-2xl rounded-md shadow-sm">
              <input
                type="text"
                name="job_three_years_experience"
                id="job_three_years_experience"
                onChange={(e) =>
                  setData("job_three_years_experience", e.target.value)
                }
                value={data.job_three_years_experience}
                autoComplete="job_three_years_experience"
                className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
              />
              {errors.job_three_years_experience && (
                <div>{errors.job_three_years_experience}</div>
              )}
            </div>
          </div>
        </div>
        <div className="space-y-1 ">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mt-6">
            Qualifications & Skills
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            This where you can input your qualification information
          </p>
        </div>
        <div className="mt-6">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
            <label
              htmlFor="qualifications"
              className="text-sm font-medium text-gray-500"
            >
              Qualifications
            </label>

            <div className="max-w-2xl">
              <Select
                options={qualifications}
                isMulti={true}
                styles={SelectStyles}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                value={data.qualification_ids}
                onChange={(e) => setData("qualification_ids", e)}
              />
              {errors.qualification_ids && (
                <div>{errors.qualification_ids}</div>
              )}

              {data.qualification_ids.map((q) => (
                <div className="bg-gray-100 mt-3 rounded p-4" key={q.id}>
                  <h3 className="font-medium leading-6 text-gray-900">
                    {q.name}
                  </h3>
                  <div className="mt-3 flex flex-col">
                    <label
                      htmlFor="expiry_date"
                      className="text-sm font-medium text-gray-700"
                    >
                      Expiry Date
                    </label>
                    <DatePicker
                      wrapperClassName="w-full"
                      dateFormat="dd/MM/yyyy"
                      className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                      selected={
                        q.expiry_date ? moment(q.expiry_date).toDate() : null
                      }
                      onChange={(date) => setExpiryDate(date, q.id)}
                    />
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="file"
                      className="text-sm font-medium text-gray-700"
                    >
                      Certificate
                    </label>
                    <div>
                      {(q.path && q.path.url) || q.temp_path ? (
                        <a
                          href={q.temp_path ? q.temp_path : q.path.url}
                          target="_blank"
                          className="inline-flex mt-0.5 items-center rounded-full cursor-pointer bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 text-green-800 mr-1.5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          View {q.name}
                        </a>
                      ) : (
                        <button
                          onClick={() => fileInput[q.id].current.click()}
                          type="button"
                          className="inline-flex mt-1 items-center rounded-md bg-orange-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                        >
                          Upload
                          <ArrowUpTrayIcon
                            className="-mr-1 ml-2 h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      )}

                      <input
                        type="file"
                        className="hidden"
                        // value={data.file}
                        ref={fileInput[q.id]}
                        onChange={(e) => {
                          prepareFile(q.id, e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
            <dt className="text-sm font-medium text-gray-500">
              Curriculum Vitae
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 justify-end">
              {progress ? (
                <progress value={progress.percentage} max="100">
                  {progress.percentage}%
                </progress>
              ) : data.resume_picture && data.resume_picture.url ? (
                <a
                  href={
                    data.resume_picture.temp_path
                      ? data.resume_picture.temp_path
                      : data.resume_picture.url
                  }
                  target="_blank"
                  className="inline-flex mt-0.5 items-center rounded-full cursor-pointer bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-green-800 mr-1.5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  View CV
                </a>
              ) : null}
              <span className="ml-4 flex flex-shrink-0 items-center space-x-4">
                <button
                  type="button"
                  onClick={() => cvInput.current.click()}
                  className="rounded-md bg-white font-medium text-orange-600 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                >
                  Update
                </button>

                <input
                  type="file"
                  className="hidden"
                  // value={data.resume}
                  ref={cvInput}
                  onChange={(e) => setData("resume_picture", e.target.files[0])}
                />

                {data.resume_picture?.url && (
                  <>
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      onClick={() => setData({ ...data, resume_picture: "" })}
                      className="rounded-md bg-white font-medium text-orange-600 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                    >
                      Remove
                    </button>
                  </>
                )}
                {data.resume_picture?.temp_path && (
                  <>
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      onClick={() => setData({ ...data, resume_picture: "" })}
                      className="rounded-md bg-white font-medium text-orange-600 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                    >
                      Remove
                    </button>
                  </>
                )}
              </span>
            </dd>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={processing}
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default JobSeeker;
