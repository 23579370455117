import React, { useState } from "react";
import Layout from "../../Layouts/Layout";
import NoMenuLayout from "../../Layouts/NoMenuLayout";
import { useForm } from "@inertiajs/inertia-react";

const SetAccountType = () => {
  const { data, setData, errors, reset, processing } = useForm({
    main_account_type: "job_seeker",
  });

  function submit(e) {
    e.preventDefault();
    post("/profile/set_main_account_type", data, {
      forceFormData: true,
    });
  }

  return (
    <>
      <main className="flex-1">
        <div className="relative mx-auto max-w-7xl">
          <div className="pb-16">
            <div className="px-4 sm:px-6 md:px-0">
              <div className="py-6">
                {/* Tabs */}
                <div className="">
                  <p>We need to know the account you will use the most</p>
                  <label htmlFor="selected-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="selected-tab"
                    name="selected-tab"
                    value={data.main_account_type}
                    onChange={(t) => {
                      setData("main_account_type", t);
                    }}
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                  >
                    <option value="job_seeker">Job Seeker</option>
                    <option value="recruiter">Recruiter</option>
                    <option value="accommodation">Accommodation</option>
                    <option value="training">Training Provider</option>
                    <option value="contractor">Contractor</option>
                  </select>
                </div>
                <button
                  type="submit"
                  disabled={processing}
                  onClick={submit}
                  className="mt-4 inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
SetAccountType.layout = (page) => (
  <NoMenuLayout children={page} title="Set Account Type" />
);

export default SetAccountType;
