import React, { useState, useEffect } from "react";
import Layout from "../../Layouts/Layout";
import { usePage } from "@inertiajs/inertia-react";
import { useForm } from "@inertiajs/inertia-react";
import { useFormik } from "formik";

export const Setting = ({setting}) => {

    const { user } = usePage().props;

    const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(setting?.sms ? true : false);

    const { data, setData, put, processing, progress, errors, reset } = useForm({
        job_matching_skills: setting?.job_matching_skills ? setting?.job_matching_skills : false,
        push: true,
        email: setting?.email ? setting?.email : false,
        sms: setting?.sms ? setting?.sms : false,
        verified_phone_number: setting?.verified_phone_number ? setting?.verified_phone_number : "",
    });

    const formik = useFormik({
        initialValues: {
            job_matching_skills: setting?.job_matching_skills ? setting?.job_matching_skills : false,
            push: true,
            email: setting?.email ? setting?.email : false,
            sms: setting?.sms ? setting?.sms : false,
            verified_phone_number: setting?.verified_phone_number ? setting?.verified_phone_number : "",
        },
        enableReinitialize: true,
        onSubmit: async () => {
            put('/setting/update');
        },
    });

    useEffect(() => {
        // Update the data in useForm hook when Formik values change
        setData(formik.values);
      }, [formik.values, setData]);
 return (
  <>
    <section className="bg-white">
        <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Settings</h2>
            </div>
            <div className="mx-auto max-w-screen-md  mb-8 lg:mb-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className={`flex flex-col p-6 mx-auto max-w-lg text-gray-900 bg-white rounded-lg border border-gray-300 shadow xl:p-8`}>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <fieldset>
                                    <p className="text-base font-medium text-gray-900">We will send you a notification whenever:</p>
                                    <div className="mt-4 space-y-4">
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                            <input
                                                id="application"
                                                name="application"
                                                type="checkbox"
                                                className="disable:bg-gray-300 focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded"
                                                checked={true}
                                                disabled={true}
                                            />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="comments" className="font-medium text-gray-400">Employer chooses your application (Required)</label>
                                            </div>
                                        </div>
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                            <input
                                                id="job_matching_skills"
                                                name="job_matching_skills"
                                                type="checkbox"
                                                className="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded"
                                                checked={formik.values.job_matching_skills}
                                                onChange={formik.handleChange}
                                            />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="candidates" className="font-medium text-gray-700">Job matching your skills is available</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <p className="text-base font-medium text-gray-900">Please choose how you went to receive these notifications:</p>
                                    </div>
                                    <div className="mt-4 space-y-4">
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                            <input
                                                id="push"
                                                name="push"
                                                type="checkbox"
                                                className="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded"
                                                checked={true}
                                                disabled={true}
                                            />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="comments" className="font-medium text-gray-400">Push Notifications (Required)</label>
                                            </div>                                    
                                        </div>
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                            <input
                                                id="email"
                                                name="email"
                                                type="checkbox"
                                                className="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded"
                                                checked={formik.values.email}
                                                onChange={formik.handleChange}
                                            />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="candidates" className="font-medium text-gray-700">E-mail messages</label>
                                            </div>
                                        </div>
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <input
                                                    id="sms"
                                                    name="sms"
                                                    type="checkbox"
                                                    className="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded"
                                                    checked={formik.values.sms}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        setShowPhoneNumberInput(e.target.checked);
                                                    }}
                                                />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="offers" className="font-medium text-gray-700">SMS Text messages</label>
                                            </div>
                                        </div>
                                        {showPhoneNumberInput && (
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <input
                                                    id="verified_phone_number"
                                                    name="verified_phone_number"
                                                    type="text"
                                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                                                    value={formik.values.verified_phone_number}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </fieldset>
                            </div>
                        
                    </div>
                    <div className="mx-auto text-center">
                        <button
                            type="submit"
                            className={`inline-flex items-center mt-3 rounded-md border border-transparent bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
  </>
  );
};



Setting.layout = (page) => (
  <Layout
    children={page}
  />
);

export default Setting;
