import React, { useState, useEffect } from "react";
import { usePage } from "@inertiajs/inertia-react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

export const NoticeMessage = () => {
  const [show, setShow] = useState(true);
  const { flash } = usePage().props;

  useEffect(() => {
    if (show && flash.message) {
      const timeoutId = setTimeout(() => {
        setShow(false);
      }, 10000);

      return () => clearTimeout(timeoutId);
    }
  }, [show, flash.message]);

  if (show && flash.message) {
    return (
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 pt-6">
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-green-700">{flash.message}</p>
              <p className="mt-3 text-sm md:mt-0 md:ml-6">
                <button
                  onClick={() => setShow(false)}
                  type="button"
                  className="whitespace-nowrap font-medium text-green-700 hover:text-green-600"
                >
                  Dismiss
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default NoticeMessage;
