import React from "react";
import Layout from "../../Layouts/Layout";
import {
  CalendarIcon,
  MapPinIcon,
  CreditCardIcon,
  InformationCircleIcon,
  BriefcaseIcon,
} from "@heroicons/react/20/solid";
import Pagination from "../../Components/Pagniation";
import moment from "moment";

const Recruiters = ({ jobs, job_owner, count,current_user }) => {
  const primary_button = {
    name: "Create Job",
    link: "/jobs/new",
  };
  return (
    <Layout
      title="Recruiters"
      primary_button={job_owner && (current_user?.plan_status == "paid" || current_user?.plan_status == "active" || current_user?.plan_status == "trialing") && current_user?.subscription_id ? primary_button : null}
    >
      {/* {(current_user.plan_status != "paid" || current_user?.plan_status != "trialing" || current_user?.plan_status != "active") && !current_user.subscription_id ? (
        <>
        <div className="rounded-md bg-red-50 p-4 mb-6">
          <div className="flex">
            <div className="flex-shrink-0 pt-1">
              <CreditCardIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true" />
            </div>
            <div className="ml-3 md:justify-between">
              <p className="font-bold text-red-700">You are almost there!</p>
              <p className="text-sm text-red-700">
                Please complete your account! We updated our application, so you must follow the remaining instructions. We added a one-month free trial and stopped offering unlimited free versions.
              </p>
            </div>
            <div className="flex-shrink-0 pt-5">
                <a
                  href="/subscriptions"
                  className="whitespace-nowrap font-medium text-red-700 hover:text-blue-600"
                >
                  Active
                  <span aria-hidden="true"> &rarr;</span>
                </a>
            </div>
          </div>
        </div>
        </>
      ) : null} */}

      {!job_owner ? (
        <div className="rounded-md bg-blue-50 p-4 mb-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                You must have update your recruiter profile before you can
                create a job
              </p>
              <p className="mt-3 text-sm md:mt-0 md:ml-6">
                <a
                  href="/profile?type=recruiter"
                  className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                >
                  Update profile
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      ) : null}
      <div className="overflow-hidden bg-white shadow sm:rounded-md border-gray-200 border">
        <ul role="list" className="divide-y divide-gray-200">
          {jobs &&
            jobs.map((position) => (
              <li key={position.id}>
                <a
                  href={"/job_owners/" + position.id}
                  className="block hover:bg-gray-50"
                >
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <p className="truncate text-sm font-medium text-gray-800">
                        {position.title}
                      </p>
                      <div className="ml-2 flex flex-shrink-0">
                        <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          {position.type}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="flex items-center text-sm text-gray-500">
                          <BriefcaseIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {position.trades.length
                            ? position.trades[0].name
                            : "Unknown name"}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                          <MapPinIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {position.location.name}
                        </p>
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <CalendarIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          Posted on{" "}
                          <time
                            dateTime={
                              position.created_at
                                ? moment(position.created_at).format(
                                    "LL"
                                  )
                                : null
                            }
                          >
                            {position.created_at
                              ? moment(position.created_at).format("LL")
                              : null}
                          </time>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
        </ul>
      </div>
      <Pagination total={count} />
    </Layout>
  );
};

export default Recruiters;
