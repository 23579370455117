import React, { Fragment, useState, useEffect } from "react";
import Layout from "../../Layouts/Layout";
import {
  LinkIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  PencilIcon,
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  BriefcaseIcon,
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CurrencyPoundIcon,
  EnvelopeIcon,
  ArrowRightCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { Disclosure, Listbox, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import Matches from "../../Components/Recruiters/Matches";
import Applications from "../../Components/Recruiters/Applications";
import Confirmed from "../../Components/Recruiters/Confirmed";
import axios from "axios";
import { Inertia } from "@inertiajs/inertia";

const RecruiterJob = ({ job, matches, current_user }) => {
  const publishingOptions = [
    {
      name: "Active",
      description: "This job posting can be viewed by anyone who has the link.",
      current: job.status == "inprogress" || job.status == "open",
      status: "open",
    },
    {
      name: "Paused",
      description: "This job posting will no longer be publicly accessible.",
      current: job.status == "paused",
      status: "paused",
    },
    {
      name: "Complete",
      description: "This job is complete.",
      current: job.status == "completed",
      status: "completed",
    },
  ];

  const [selected, setSelected] = useState(publishingOptions[0]);
  const [applicants, setApplicants] = useState([]);
  const [confirmed, setConfirmed] = useState([]);
  const [tab, setTab] = useState("matches");

  useEffect(() => {
    job.applications.map((a) => {
      if (a.accepted) {
        setConfirmed([...confirmed, a]);
      } else {
        setApplicants([...confirmed, a]);
      }
    });
  }, [job.applications]);

  useEffect(() => {
    currentStatus();
  }, []);

  const currentStatus = () => {
    switch (job.status) {
      case "open":
        setSelected({
          name: "Active",
          description:
            "This job posting can be viewed by anyone who has the link.",
          current: true,
        });
        break;
      case "inprogress":
        setSelected({
          name: "Active",
          description:
            "This job posting can be viewed by anyone who has the link.",
          current: true,
        });
        break;
      case "paused":
        setSelected({
          name: "Paused",
          description:
            "This job posting will no longer be publicly accessible.",
          current: true,
        });
        break;
      case "completed":
        setSelected({
          name: "Completed",
          description: "This job posting is complete",
          current: true,
        });
        break;
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const tabs = [
    { name: "Matches", count: matches.length, current: tab == "matches" },
    {
      name: "Applicants",
      count: applicants.length,
      current: tab == "applicants",
    },
    { name: "Confirmed", count: confirmed.length, current: tab == "confirmed" },
  ];

  const SectionSwitch = () => {
    switch (tab) {
      case "matches":
        return <Matches matches={matches} job={job} />;
      case "applicants":
        return <Applications applications={applicants} job={job} />;
      case "confirmed":
        return <Confirmed applications={confirmed} job={job} />;
      default:
        break;
    }
  };

  const submit = async (s) => {
    let route;

    switch (s.name) {
      case "Active":
        route = "/resume_job/" + job.id;
        break;
      case "Paused":
        route = "/pause_job/" + job.id;
        break;
      case "Complete":
        route = "/complete_job/" + job.id;
        break;
    }

    await axios({
      url: route,
      method: "PUT",
    })
      .then((response) => {
        setSelected({
          current: true,
          description: s.description,
          name: s.name,
        });
      })

      .catch((error) => {
        console.log(error);
      });
  };

  function deleteJob(e) {
    e.preventDefault();
    Inertia.delete("/jobs/" + job.id);
  }

  return (
    <Layout>
      <header className="bg-gray-50 py-8">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
          <div className="min-w-0 flex-1">
            <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {job.title}
            </h1>
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
              <div className="mt-2 flex items-center text-sm text-gray-500 capitalize">
                <BriefcaseIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {job.trades.length ? job.trades[0].name : "Unknown Trade"}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <MapPinIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {job.location && job.location.city}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CurrencyPoundIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                £{job.rate}{" "}
                <span className="capitalize ml-1 text-xs">
                  ({job.interval})
                </span>
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                Starts {moment(job.start_date).format("LL")}
              </div>
            </div>
          </div>
          <div className="mt-5 flex xl:mt-0 xl:ml-4">
            { (current_user?.plan_status == "paid" || current_user?.plan_status == "active" || current_user?.plan_status == "trialing") && current_user?.subscription_id ? 
            <span className="hidden sm:block">
              <a
                href={"/job_owners/" + job.id + "/edit"}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              >
                <PencilIcon
                  className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Edit
              </a>
            </span>
            : null 
            }
            <span className="ml-3 hidden sm:block">
              <a
                target={"blank"}
                href={"/jobs/" + job.id}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              >
                <LinkIcon
                  className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                View
              </a>
            </span>
            {(current_user?.plan_status == "paid" || current_user?.plan_status == "active" || current_user?.plan_status == "trialing") && current_user?.subscription_id ?
              <span className="ml-3 hidden sm:block">
                <button
                  type="button"
                  onClick={deleteJob}
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  <TrashIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Delete
                </button>
              </span>
              : null
            }

            <Listbox
              as="div"
              value={selected}
              onChange={(s) => {
                (current_user?.plan_status == "paid" || current_user?.plan_status == "active" || current_user?.plan_status == "trialing") && current_user?.subscription_id ? submit(s) : null
              }}
              className="sm:ml-3"
            >
              {({ open }) => (
                <>
                  <Listbox.Label className="sr-only">
                    {" "}
                    Change published status{" "}
                  </Listbox.Label>
                  <div className="relative">
                    <div className="inline-flex divide-x divide-orange-600 rounded-md shadow-sm">
                      <div className="inline-flex divide-x divide-orange-600 rounded-md shadow-sm">
                        <div className="inline-flex items-center rounded-l-md border border-transparent bg-orange-500 py-2 pl-3 pr-4 text-white shadow-sm">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          <p className="ml-2.5 text-sm font-medium">
                            {selected.name}
                          </p>
                        </div>
                        <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-orange-500 p-2 text-sm font-medium text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50">
                          <span className="sr-only">
                            Change published status
                          </span>
                          <ChevronDownIcon
                            className="h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                        </Listbox.Button>
                      </div>
                    </div>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute left-0 z-10 mt-2 -mr-1 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0">
                        {publishingOptions.map((option) => (
                          <Listbox.Option
                            key={option.name}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-orange-500"
                                  : "text-gray-900",
                                "cursor-default select-none p-4 text-sm"
                              )
                            }
                            value={option}
                          >
                            {({ active }) => (
                              <div className="flex flex-col">
                                <div className="flex justify-between">
                                  <p
                                    className={
                                      active ? "font-semibold" : "font-normal"
                                    }
                                  >
                                    {option.name}
                                  </p>
                                  {active ? (
                                    <span
                                      className={
                                        active
                                          ? "text-white"
                                          : "text-orange-500"
                                      }
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </div>
                                <p
                                  className={classNames(
                                    active
                                      ? "text-orange-200"
                                      : "text-gray-500",
                                    "mt-2"
                                  )}
                                >
                                  {option.description}
                                </p>
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>

            {/* Dropdown */}
            <Menu as="div" className="relative ml-3 sm:hidden">
              <Menu.Button className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
                More
                <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 -mr-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Edit
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        View
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </header>
      <main className="pt-8 pb-16">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="px-4 sm:px-0">
            <h2 className="text-lg font-medium text-gray-900">Candidates</h2>
            <p className="text-gray-500 text-sm">
              Instant matches have not applied for your position however, they
              do meet your requirements and live within a close radius. When
              clicking on their profile you can view their experience and
              contact details
            </p>
            {/* Tabs */}
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="mt-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={() => setTab(tab.name.toLowerCase())}
                      className={classNames(
                        tab.current
                          ? "border-orange-500 text-orange-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                    >
                      {tab.name}
                      {tab.count ? (
                        <span
                          className={classNames(
                            tab.current
                              ? "bg-orange-100 text-orange-600"
                              : "bg-gray-100 text-gray-900",
                            "hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                          )}
                        >
                          {tab.count}
                        </span>
                      ) : null}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          {SectionSwitch()}
        </div>
      </main>
    </Layout>
  );
};

export default RecruiterJob;
