import React from "react";
import Layout from "../../Layouts/Layout";
import { CreditCardIcon, MapPinIcon } from "@heroicons/react/20/solid";
import Pagination from "../../Components/Pagniation";

const urlGenration = (encodedUrl) => {
  const decodedURL = decodeURIComponent(encodedUrl);
  const substrings = decodedURL.split('https://');
  const count = substrings[0].length === 0 ? substrings.length - 1 : substrings.length;
  var newURL = decodedURL;
  if(count >= 2){
    newURL = newURL.replace('https://advertapproval.s3.eu-central-1.amazonaws.com/uploads/','');
    newURL = newURL.replace('https://thetradesmanapp.s3.eu-west-1.amazonaws.com/uploads/','');
    newURL = newURL.replace('https://thetradesmanapp.s3.amazonaws.com/uploads/','');
  }
  return newURL;
}

const Digs = ({ digs, count, current_user }) => {

  const primary_button = {
    name: "Create Accommodation",
    link: "/accommodation/new",
  };
  
  const secondary_button = {
    name: "View accommodation map",
    link: "/accommodation/map",
  };

  return (
    <Layout
      title="Accommodation"
      primary_button={current_user?.main_account_type == "accommodation" ? primary_button : null}
      secondary_button={secondary_button}
      >
        {/* {(current_user.plan_status != "paid" || current_user?.plan_status != "trialing" || current_user?.plan_status != "active") && !current_user.subscription_id ? (
          <>
          <div className="rounded-md bg-red-50 p-4 mb-6">
            <div className="flex">
              <div className="flex-shrink-0 pt-1">
                <CreditCardIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true" />
              </div>
              <div className="ml-3 md:justify-between">
                <p className="font-bold text-red-700">You are almost there!</p>
                <p className="text-sm text-red-700">
                  Please complete your account! We updated our application, so you must follow the remaining instructions. We added a one-month free trial and stopped offering unlimited free versions.
                </p>
              </div>
              <div className="flex-shrink-0 pt-5">
                  <a
                    href="/subscriptions"
                    className="whitespace-nowrap font-medium text-red-700 hover:text-blue-600"
                  >
                    Active
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
              </div>
            </div>
          </div>
          </>
        ) : null} */}
      <div className="overflow-hidden bg-white ">
        <div className="grid grid-cols-12 gap-6 pb-6">
          {digs.map((dig) => (
            <a
              href={"/accommodation/" + dig.id}
              className="block hover:bg-gray-50 col-span-12 md:col-span-4 border border-gray-300 shadow sm:rounded-md"
            >
              <div key={dig.id} className="">
                <div>
                  <img
                    src={
                      dig.dig_images.length
                        ? urlGenration(dig.dig_images[0].image.url)
                        : "https://www.propertypriceadvice.co.uk/wp-content/uploads/2019/02/house-red-features-home-insurance.jpg"
                    }
                    className="h-48 object-cover w-full rounded-t-md"
                  />
                </div>

                <div className="px-3 py-3">
                  <div className="flex items-center justify-between">
                    <p className="truncate font-medium text-gray-800 text-base">
                      {dig.name}
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 mt-1">
                    <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      Bedrooms: {dig.bedrooms !== '' ? dig.bedrooms : ' - ' ?? ' - '} | Bathrooms: {dig.bathrooms !== '' ? dig.bedrooms : ' - ' ?? ' - '}
                    </p>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        <MapPinIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {dig.post_code !== '' ? dig.post_code : ' - ' ?? ' - '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
      <Pagination total={count} />
    </Layout>
  );
};

export default Digs;
