import React from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { usePage } from "@inertiajs/inertia-react";

export default function Errors() {
  const [renderErrors, setRenderErrors] = React.useState([]);

  const { errors } = usePage().props;

  React.useEffect(() => {
    if (errors) {
      setRenderErrors(Object.entries(errors));
      setTimeout(() => setRenderErrors([]), 5000); // hide errors after 5 seconds
    }
  }, [errors]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (renderErrors.length) {
    return (
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 pt-6">
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                There were {renderErrors.length} errors with your submission
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <ul role="list" className="list-disc space-y-1 pl-5">
                  {renderErrors.map((e) => (
                    <li>{capitalizeFirstLetter(e[0]) + " " + e[1]}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
