import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../Layouts/Layout";
import { CalendarIcon, MapPinIcon, UsersIcon } from "@heroicons/react/20/solid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import LocationComponent from "../../Components/LocationComponent";
import TradesComponent from "../../Components/TradesComponent";
import { useForm } from "@inertiajs/inertia-react";
import { SelectStyles } from "../../styles/ReactSelect";
import Dropzone from "../../Components/Dropzone";
import ImageGallery from "../../Components/ImageGallery";
import remove from "babel-plugin-transform-react-remove-prop-types/lib/remove";
import { Inertia } from "@inertiajs/inertia";

const EditDig = ({ locations, dig }) => {
  const [images, setImages] = useState(dig.dig_images);

  const { data, setData, put, processing, errors, reset } = useForm({
    reference_number: dig.reference_number,
    name: dig.name,
    description: dig.description,
    location_id: dig.location_id,
    bedrooms: dig.bedrooms,
    bathrooms: dig.bathrooms,
    property_number: dig.property_number,
    address_one: dig.address_one,
    address_two: dig.address_two,
    county: dig.county,
    post_code: dig.post_code,
    latitude: dig.latitude,
    dig_images: dig.dig_images,
    longitude: dig.longitude,
    rent: dig.rent,
    phone_number: dig.phone_number,
    wifi: dig.wifi,
    garden: dig.garden,
    bills: dig.bills,
    parking: dig.parking,
    kitchen: dig.kitchen,
    tv: dig.tv,
    washing_machine: dig.washing_machine,
    website: dig.website,
    active: dig.active,
  });

  function submit(e) {
    e.preventDefault();
    put("/digs/" + dig.id);
  }

  function deleteDig(e) {
    e.preventDefault();
    Inertia.delete("/accommodation/" + dig.id);
  }

  const onDrop = (acceptedFiles) => {
    let i = [...data.dig_images];
    acceptedFiles.map((file) => {
      const objectUrl = URL.createObjectURL(file);
      file.temp_url = objectUrl;
      // setImages((prevArr) => [...prevArr, file]);
      return file;
    });
    i.push(...acceptedFiles);
    setData("dig_images", i);
  };

  const removeFile = (i) => {
    let images = data.dig_images;
    images[i].deleted = true;

    setData("dig_images", images);
  };

  return (
    <>
      <div className="overflow-hidden bg-white px-2">
        <form
          onSubmit={submit}
          className="space-y-8 divide-y divide-gray-200 py-2"
        >
          <div className="space-y-8 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Accommodation Details
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  We just need some basic information about the accommodation to
                  get going.
                </p>
              </div>

              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reference Number
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <p className="text-sm text-gray-500 my-2">
                      This is your personal reference number for the
                      accommodation
                    </p>
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        name="reference_number"
                        id="reference_number"
                        placeholder="Accommodation reference_number..."
                        onChange={(e) =>
                          setData("reference_number", e.target.value)
                        }
                        value={data.reference_number}
                        autoComplete="accommodation_reference_number"
                        className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Accommodation Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Accommodation name..."
                        onChange={(e) => setData("name", e.target.value)}
                        value={data.name}
                        autoComplete="accommodation_name"
                        className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="bedrooms"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Bedrooms
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        name="bedrooms"
                        id="bedrooms"
                        placeholder="Bedrooms..."
                        onChange={(e) => setData("bedrooms", e.target.value)}
                        value={data.bedrooms}
                        autoComplete="bedrooms"
                        className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="bathrooms"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Bathrooms
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        name="bathrooms"
                        id="bathrooms"
                        placeholder="Bathrooms..."
                        onChange={(e) => setData("bathrooms", e.target.value)}
                        value={data.bathrooms}
                        autoComplete="bathrooms"
                        className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="rent"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Rent
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        name="rent"
                        id="rent"
                        placeholder="Rent..."
                        onChange={(e) => setData("rent", e.target.value)}
                        value={data.rent}
                        autoComplete="rent"
                        className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Accommodation Description
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                      onChange={(e) => setData("description", e.target.value)}
                      value={data.description}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Describe your accommodation
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10 sm:border-t sm:border-gray-200">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Job Location
                </h3>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="rent"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Property Number / Name
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="text"
                      name="property_number"
                      id="rent"
                      placeholder="Property number..."
                      onChange={(e) =>
                        setData("property_number", e.target.value)
                      }
                      value={data.property_number}
                      autoComplete="property_number"
                      className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="rent"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Address One
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="text"
                      name="address_one"
                      id="rent"
                      placeholder="Address Line One..."
                      onChange={(e) => setData("address_one", e.target.value)}
                      value={data.address_one}
                      autoComplete="rent"
                      className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="rent"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Address Two
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="text"
                      name="address_two"
                      id="rent"
                      placeholder="Address Line Two..."
                      onChange={(e) => setData("address_two", e.target.value)}
                      value={data.address_two}
                      autoComplete="rent"
                      className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="rent"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  County
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="text"
                      name="county"
                      id="rent"
                      placeholder="County..."
                      onChange={(e) => setData("county", e.target.value)}
                      value={data.county}
                      autoComplete="rent"
                      className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="rent"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Post Code
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="text"
                      name="post_code"
                      id="rent"
                      placeholder="Post Code..."
                      onChange={(e) => setData("post_code", e.target.value)}
                      value={data.post_code}
                      autoComplete="rent"
                      className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10 sm:border-t sm:border-gray-200">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Additional Details
                </h3>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Contact Number
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <p className="text-sm text-gray-500 my-2">
                    This is the conect number that will be posted on the advert
                    to contact
                  </p>
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="tel"
                      name="phone_number"
                      id="phone_number"
                      placeholder="Phone number..."
                      onChange={(e) => setData("phone_number", e.target.value)}
                      value={data.phone_number}
                      autoComplete="phone_number"
                      className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Website
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <input
                      type="text"
                      name="website"
                      id="phone_number"
                      placeholder="Website..."
                      onChange={(e) => setData("website", e.target.value)}
                      value={data.website}
                      autoComplete="website"
                      className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              <div className="space-y-6 sm:space-y-5 sm:border-t sm:border-gray-200">
                <div className="pt-6 sm:pt-5">
                  <div role="group" aria-labelledby="label-notifications">
                    <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                      <div>
                        <div
                          className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                          id="label-notifications"
                        >
                          What does this accommodation include?
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="max-w-lg">
                          <div className="mt-4 space-y-4">
                            <div className="flex items-center mt-4">
                              <input
                                id="wifi"
                                name="wifi"
                                checked={data.wifi}
                                type="checkbox"
                                onChange={(e) =>
                                  setData("wifi", e.target.checked)
                                }
                                className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                              <label
                                htmlFor="wifi"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                WiFi
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                id="bills"
                                name="bills"
                                checked={data.bills}
                                type="checkbox"
                                onChange={(e) =>
                                  setData("bills", e.target.checked)
                                }
                                className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                              <label
                                htmlFor="bills"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                Bills Included
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                id="parking"
                                name="parking"
                                checked={data.parking}
                                type="checkbox"
                                onChange={(e) =>
                                  setData("parking", e.target.checked)
                                }
                                className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                              <label
                                htmlFor="parking"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                Parking
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                id="kitchen"
                                name="kitchen"
                                checked={data.kitchen}
                                type="checkbox"
                                onChange={(e) =>
                                  setData("kitchen", e.target.checked)
                                }
                                className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                              <label
                                htmlFor="kitchen"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                Kitchen
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                id="tv"
                                name="tv"
                                checked={data.tv}
                                type="checkbox"
                                onChange={(e) =>
                                  setData("tv", e.target.checked)
                                }
                                className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                              <label
                                htmlFor="tv"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                TV
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                id="washing_machine"
                                name="washing_machine"
                                checked={data.washing_machine}
                                type="checkbox"
                                onChange={(e) =>
                                  setData("washing_machine", e.target.checked)
                                }
                                className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                              <label
                                htmlFor="washing_machine"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                Washing Machine
                              </label>
                            </div>

                            <div className="flex items-center">
                              <input
                                id="fixed"
                                name="garden"
                                type="checkbox"
                                checked={data.garden}
                                onChange={(e) =>
                                  setData("garden", e.target.checked)
                                }
                                className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                              <label
                                htmlFor="fixed"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                Garden
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-6 sm:space-y-5 sm:border-t sm:border-gray-200">
                <div className="pt-6 sm:pt-5">
                  <div role="group" aria-labelledby="label-notifications">
                    <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                      <div>
                        <div
                          className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                          id="label-notifications"
                        >
                          Is this job active?
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="max-w-lg">
                          <div className="mt-4 space-y-4">
                            <div className="flex items-center mt-4">
                              <input
                                id="wifi"
                                name="wifi"
                                checked={data.active}
                                type="checkbox"
                                onChange={(e) =>
                                  setData("active", e.target.checked)
                                }
                                className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                              <label
                                htmlFor="active"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                Active
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10 sm:border-t sm:border-gray-200">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Images
                  </h3>
                </div>
                <div className="flex flex-col items-center justify-center w-full">
                  <ImageGallery
                    images={data.dig_images}
                    deleteFile={removeFile}
                  />
                  <Dropzone onDrop={onDrop} accept={"image/*"} />
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-between">
              <button
                type="button"
                disabled={processing}
                onClick={deleteDig}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Delete Accommodation
              </button>
              <button
                type="submit"
                disabled={processing}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
              >
                Update Accommodation
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

EditDig.layout = (page) => (
  <Layout children={page} title="Edit Accommodation" />
);

export default EditDig;
