import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BuildingOffice2Icon,
  CalendarIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UserCircleIcon,
  UserIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { usePage } from "@inertiajs/inertia-react";
import NoticeMessage from "../Components/Flash/NoticeMessage";
import Errors from "../Components/Errors";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NoMenuLayout({
  children,
  title,
  primary_button,
  secondary_button,
}) {
  const { user, thumbnail } = usePage().props;

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html className="h-full bg-gray-100">
        <body className="h-full">
        ```
      */}
      <div>
        <div>
          <main>
            <div>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                <div className="py-4">{children}</div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
