import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { RadioGroup } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "@inertiajs/inertia-react";
import LocationComponent from "../LocationComponent";
import { useFormik } from "formik";
import * as Yup from "yup";

export const General = ({ general, locations }) => {
  const fileInput = useRef();

  const { data, setData, put, processing, progress, errors, reset } = useForm({
    id: general.id,
    name: general.name,
    email: general.email,
    location_id: general.location_id,
    image: {},
    // main_account_type: general.main_account_type,
    inputed_file: general.thumbnail,
  });

  var formValidation = Yup.object().shape({
    location_id: Yup.string().required("Location must be required"),
  })
  
  
  const formik = useFormik({
      initialValues: {
          location_id: data?.location_id ?? "",
      },
      enableReinitialize: true,
      validationSchema: formValidation,
      onSubmit: async (values, { resetForm }) => {
          // handle form submission
          data._method = "put";
          put("/profile/general");
      },
  });

  const prepareFile = (e) => {
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setData({
      ...data,
      image: e.target.files[0],
      inputed_file: objectUrl,
    });
    // setData("image", e.target.files[0]);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            General
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            General information about your profile.
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <label
                htmlFor="name"
                className="text-sm font-medium text-gray-500"
              >
                Name
              </label>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setData("name", e.target.value)}
                  value={data.name}
                  autoComplete="name"
                  className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                />
                {errors.name && <div>{errors.name}</div>}
              </div>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <dt className="text-sm font-medium text-gray-500">
                Profile Image
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {progress ? (
                  <progress value={progress.percentage} max="100">
                    {progress.percentage}%
                  </progress>
                ) : (
                  <span className="flex-grow">
                    {data.inputed_file ? (
                      <img
                        className="h-12 w-12 rounded-full object-cover"
                        src={data.inputed_file}
                        alt={data.name + " Avatar"}
                      />
                    ) : (
                      <UserCircleIcon
                        className="h-9 w-9 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                )}
                <span className="ml-4 flex flex-shrink-0 items-center space-x-4">
                  <button
                    type="button"
                    onClick={() => fileInput.current.click()}
                    className="rounded-md bg-white font-medium text-orange-600 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                  >
                    Update
                  </button>

                  <input
                    type="file"
                    className="hidden"
                    value={data.file}
                    ref={fileInput}
                    onChange={prepareFile}
                  />

                  {data.image && (
                    <>
                      <span className="text-gray-300" aria-hidden="true">
                        |
                      </span>
                      <button
                        type="button"
                        onClick={() =>
                          setData({ ...data, image: "", inputed_file: "" })
                        }
                        className="rounded-md bg-white font-medium text-orange-600 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                      >
                        Remove
                      </button>
                    </>
                  )}
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <label
                htmlFor="email"
                className="text-sm font-medium text-gray-500"
              >
                Email
              </label>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={(e) => setData("email", e.target.value)}
                  value={data.email}
                  autoComplete="email"
                  className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                />
                {errors.email && <div>{errors.email}</div>}
              </div>
            </div>
            {/* <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <label
                htmlFor="main_account_type"
                className="text-sm font-medium text-gray-500"
              >
                Main account type
              </label>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <select
                  id="main_account_type"
                  name="main_account_type"
                  onChange={(e) => setData("main_account_type", e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                  value={data.main_account_type}
                >
                  <option value="recruiter">Recruiter</option>
                  <option value="job_seeker">Job Seeker</option>
                  <option value="accommodation">Accommodation</option>
                  <option value="training">Training</option>
                </select>
              </div>
            </div> */}
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
              <label
                htmlFor="location"
                className="text-sm font-medium text-gray-500"
              >
                Default Location
              </label>
              <div className="flex flex-col max-w-lg rounded-md shadow-sm">
                <LocationComponent
                  name="location_id"
                  locations={locations}
                  location={general.location}
                  onLocationChange={(loc) => { 
                    formik.setValues({ ...formik.values, location_id: loc.id }) 
                    setData("location_id", loc.id)
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.location_id && formik.errors.location_id ? <div className="text-red-400 text-sm my-1">{formik.errors.location_id}</div> : null}
              </div>
            </div>
          </dl>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={processing}
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default General;
