import React from "react";
import Layout from "../Layouts/Layout";
import { CalendarIcon, MapPinIcon, UsersIcon } from "@heroicons/react/20/solid";

const Dashboard = () => {
  return <></>;
};
Dashboard.layout = (page) => <Layout children={page} title="Dashboard" />;

export default Dashboard;
