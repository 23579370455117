import React, { Fragment, useRef, useState } from "react";
import {
  MapPinIcon,
  CalendarIcon,
  UserIcon,
  IdentificationIcon,
} from "@heroicons/react/20/solid";

export const Matches = ({ matches, job }) => {
  return (
    <>
      <div className="overflow-hidden bg-white shadow sm:rounded-md border-gray-200 border">
        {matches.length == 0 && (
          <div className="text-center my-10">
            <h3 className="mt-2 text-lg font-semibold text-gray-900">
              There are no matches yet
            </h3>
            <p className="mt-1 text-sm text-gray-500">Please check back soon</p>
          </div>
        )}
        <ul role="list" className="divide-y divide-gray-200">
          {matches.map((seeker) => (
            <li key={seeker.id}>
              <a
                href={"/job_owners/" + job.id + "/view_seeker/" + seeker.id}
                className="block hover:bg-gray-50"
              >
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="truncate text-sm font-medium text-orange-600">
                      {seeker.user && seeker.user.name
                        ? seeker.user.name
                        : seeker.first_name + " " + seeker.last_name}
                    </p>
                    <div className="ml-2 flex flex-shrink-0">
                      {seeker.number_of_requested_quals <=
                      seeker.matched_quals ? (
                        <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          Exact Match
                        </p>
                      ) : (
                        <p className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                          Partial Match
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        <MapPinIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {seeker.distance_from_job} Miles Away
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <IdentificationIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {seeker.qualifications.map((q, i) => {
                          if (i + 1 < seeker.qualifications.length) {
                            return q.name + ", ";
                          } else {
                            return q.name;
                          }
                        })}
                      </p>
                    </div>
                    {/* <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <CalendarIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <p>
                        Created on{" "}
                        <time dateTime={seeker.created_at}>
                          {seeker.created_at}
                        </time>
                      </p>
                    </div> */}
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Matches;
