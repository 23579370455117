import React, { useEffect, useState } from "react";
import Layout from "../../Layouts/Layout";
import {
  ArrowLongLeftIcon,
  CheckIcon,
  HandThumbUpIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import Loader from "../../Components/Loader";
import axios from "axios";
import Swal from 'sweetalert2';

const ViewSeeker = ({ job, job_seeker }) => {

  const [loading, setloading] = useState(false);

  const attachments = [{ name: "cv_electrician.pdf", href: "#" }];
  const eventTypes = {
    applied: { icon: UserIcon, bgColorClass: "bg-gray-400" },
    advanced: { icon: HandThumbUpIcon, bgColorClass: "bg-orange-500" },
    completed: { icon: CheckIcon, bgColorClass: "bg-green-500" },
  };

  const timeline = [];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const submit = async (e) => {
    await axios({
      url: `/applications/request`,
      method: "POST",
      data: {
        job_id: job.id,
        job_seeker_id: job_seeker.id,
      },
    })
    .then((response) => {
      if(response.status == 200 && response.data.status){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response?.data?.message,
          showConfirmButton: false,
          timer: 1500
        })
      }else{
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response?.data?.message,
          showConfirmButton: false,
          timer: 1500
        })
      }
      setloading(false);
    })
    .catch((error) => {
      setloading(false);
      Swal.fire('Error', error.message, 'error');
    });
  };

  return (
    <Layout title="View Job Seeker">
      <main className="py-10">
        {/* Page header */}
        <div className="mx-auto max-w-3xl md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                {job_seeker.user.image.url ? (
                  <img
                    className="h-16 w-16 rounded-full"
                    src={job_seeker.user.image.url}
                    alt={job_seeker.user.name}
                  />
                ) : (
                  <UserCircleIcon className="h-14 w-14 text-gray-400" />
                )}
                <span
                  className="absolute inset-0 rounded-full shadow-inner"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {job_seeker.user.name
                  ? job_seeker.user.name
                  : job_seeker.first_name + " " + job_seeker.last_name}
              </h1>
              <p className="text-sm font-medium text-gray-500">
                Match for{" "}
                <span href="#" className="text-orange-500">
                  {job.title}
                </span>
              </p>
            </div>
          </div>
          <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
            <button
              type="button"
              className="item-center inline-flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-100"
              onClick={() => {
                  setloading(true)
                  submit()
              }}
            >
              {loading ? (
                <>
                  Requesting.... <Loader />
                </>
              ) : `Request Application`}
            </button>
          </div>
        </div>

        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-2 lg:col-start-1">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg border-gray-100 border">
                <div className="px-4 py-5 sm:px-6">
                  <h2
                    id="applicant-information-title"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Candidate Information
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Personal details
                  </p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Application for
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job.trades[0].name}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Email address
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job_seeker.user.email}
                      </dd>
                    </div>
                    {/* <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Salary expectation
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        £{job_seeker.full_day_rate} per day
                      </dd>
                    </div> */}
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Phone
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job_seeker.contact_phone}
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        About
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job_seeker.about}
                      </dd>
                    </div>
                    {job_seeker.resume_picture &&
                      job_seeker.resume_picture.url && (
                        <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">
                            Resume
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <ul
                              role="list"
                              className="divide-y divide-gray-200 rounded-md border border-gray-200"
                            >
                              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                <div className="flex w-0 flex-1 items-center">
                                  <PaperClipIcon
                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-2 w-0 flex-1 truncate">
                                    CV
                                  </span>
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                  <a
                                    href={job_seeker.resume_picture.url}
                                    className="font-medium text-orange-600 hover:text-orange-500"
                                  >
                                    Download
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </dd>
                        </div>
                      )}
                  </dl>
                </div>
              </div>
            </section>

            {/* Comments
            <section aria-labelledby="notes-title">
              <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="notes-title"
                      className="text-lg font-medium text-gray-900"
                    >
                      Notes
                    </h2>
                  </div>
                  <div className="px-4 py-6 sm:px-6">
                    <ul role="list" className="space-y-8">
                      {comments.map((comment) => (
                        <li key={comment.id}>
                          <div className="flex space-x-3">
                            <div className="flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full"
                                src={`https://images.unsplash.com/photo-${comment.imageId}?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`}
                                alt=""
                              />
                            </div>
                            <div>
                              <div className="text-sm">
                                <a
                                  href="#"
                                  className="font-medium text-gray-900"
                                >
                                  {comment.name}
                                </a>
                              </div>
                              <div className="mt-1 text-sm text-gray-700">
                                <p>{comment.body}</p>
                              </div>
                              <div className="mt-2 space-x-2 text-sm">
                                <span className="font-medium text-gray-500">
                                  {comment.date}
                                </span>{" "}
                                <span className="font-medium text-gray-500">
                                  &middot;
                                </span>{" "}
                                <button
                                  type="button"
                                  className="font-medium text-gray-900"
                                >
                                  Reply
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-6 sm:px-6">
                  <div className="flex space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="min-w-0 flex-1">
                      <form action="#">
                        <div>
                          <label htmlFor="comment" className="sr-only">
                            About
                          </label>
                          <textarea
                            id="comment"
                            name="comment"
                            rows={3}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                            placeholder="Add a note"
                            defaultValue={""}
                          />
                        </div>
                        <div className="mt-3 flex items-center justify-between">
                          <a
                            href="#"
                            className="group inline-flex items-start space-x-2 text-sm text-gray-500 hover:text-gray-900"
                          >
                            <QuestionMarkCircleIcon
                              className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            <span>Some HTML is okay.</span>
                          </a>
                          <button
                            type="submit"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                          >
                            Comment
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          </div>

          <section
            aria-labelledby="timeline-title"
            className="lg:col-span-1 lg:col-start-3"
          >
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 border-gray-100 border">
              <h2
                id="timeline-title"
                className="text-lg font-medium text-gray-900"
              >
                Qualifications
              </h2>

              {/* Activity Feed */}
              <div className="mt-6 flow-root">
                <ul role="list" className="-mb-8">
                  {job_seeker.qualifications.map((item, itemIdx) => (
                    <li key={itemIdx}>
                      <div className="relative pb-8">
                        <div className="relative flex space-x-3">
                          <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                            <div>
                              <p className="text-sm text-gray-500">
                                {item.name}{" "}
                                <a
                                  href="#"
                                  className="font-medium text-gray-900"
                                >
                                  {item.target}
                                </a>
                              </p>
                            </div>
                            <div className="whitespace-nowrap text-right text-sm text-gray-500">
                              <time dateTime={item.datetime}>{item.date}</time>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 border-gray-100 border mt-8">
              <h2
                id="timeline-title"
                className="text-lg font-medium text-gray-900"
              >
                Job History
              </h2>

              {/* Activity Feed */}
              <div className="mt-6 flow-root">
                {job_seeker.job_one_role && (
                  <>
                    <div className="sm:col-span-2 mt-3">
                      <dt className="text-sm font-medium text-gray-500">
                        Job Role
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job_seeker.job_one_role}
                      </dd>
                    </div>
                    <div className="sm:col-span-2 mt-3">
                      <dt className="text-sm font-medium text-gray-500">
                        Job Description
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job_seeker.job_one_description}
                      </dd>
                    </div>
                    <div className="sm:col-span-2 mt-3">
                      <dt className="text-sm font-medium text-gray-500">
                        Experience
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job_seeker.job_one_years_experience}
                      </dd>
                    </div>
                  </>
                )}

                {job_seeker.job_two_role && (
                  <>
                    <div className="sm:col-span-2 mt-3">
                      <dt className="text-sm font-medium text-gray-500">
                        Job Role
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job_seeker.job_two_role}
                      </dd>
                    </div>
                    <div className="sm:col-span-2 mt-3">
                      <dt className="text-sm font-medium text-gray-500">
                        Job Description
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job_seeker.job_two_description}
                      </dd>
                    </div>
                    <div className="sm:col-span-2 mt-3">
                      <dt className="text-sm font-medium text-gray-500">
                        Experience
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job_seeker.job_two_years_experience}
                      </dd>
                    </div>
                  </>
                )}
                {job_seeker.job_three_role && (
                  <>
                    <div className="sm:col-span-2 mt-3">
                      <dt className="text-sm font-medium text-gray-500">
                        Job Role
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job_seeker.job_three_role}
                      </dd>
                    </div>
                    <div className="sm:col-span-2 mt-3">
                      <dt className="text-sm font-medium text-gray-500">
                        Job Description
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job_seeker.job_three_description}
                      </dd>
                    </div>
                    <div className="sm:col-span-2 mt-3">
                      <dt className="text-sm font-medium text-gray-500">
                        Experience
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {job_seeker.job_three_years_experience}
                      </dd>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
      </main>
    </Layout>
  );
};

export default ViewSeeker;
