import React from "react";

export default function Pagination({ per_page = 10, total }) {
  
  const [previous, setPrevious] = React.useState("?");
  const [next, setNext] = React.useState("?");
  const [currentPage, setCurrentPage] = React.useState(null);
  const path = window.location.pathname;

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.entries(
      Object.fromEntries(urlSearchParams.entries())
    );

    let next_string = "?";
    let previous_string = "?";
    let query;

    Promise.all(
      params.map((p, index) => {
        query = p[0];
        if (index + 1 < params.length) {
          if (query == "page") {
            setCurrentPage(p[1]);
            previous_string += "page=" + (p[1] - 1) + "&";
            next_string += "page=" + (Number(p[1]) + 1) + "&";
          } else {
            previous_string += query + "=" + (p[1] - 1) + "&";
            next_string += query + "=" + p[1] + "&";
          }
        } else {
          if (query == "page") {
            setCurrentPage(p[1]);
            previous_string += "page=" + (p[1] - 1);
            next_string += "page=" + (Number(p[1]) + 1);
          } else {
            previous_string += query + "=" + (p[1] - 1);
            next_string += query + "=" + p[1];
          }
        }
      })
    );

    setNext(next_string);
    setPrevious(previous_string);
  }, []);

  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing{" "}
          <span className="font-medium">
            {currentPage * per_page - per_page + 1}
          </span>{" "}
          to{" "}
          <span className="font-medium">
            {total >= currentPage * per_page ? currentPage * per_page : total}
          </span>{" "}
          of <span className="font-medium">{total}</span> results
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        {currentPage > 1 && (
          <a
            href={path + previous}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Previous
          </a>
        )}
        {total >= currentPage * per_page && (
          <a
            href={path + next}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Next
          </a>
        )}
      </div>
    </nav>
  );
}
