import React, { useState, useEffect } from "react";
import Layout from "../../Layouts/Layout";
import { CalendarIcon, MapPinIcon, UsersIcon } from "@heroicons/react/20/solid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import LocationComponent from "../../Components/LocationComponent";
import TradesComponent from "../../Components/TradesComponent";
import { useForm } from "@inertiajs/inertia-react";
import { SelectStyles } from "../../styles/ReactSelect";

const CreateJob = ({ seekers, locations, trades, qualifications }) => {
  const [pounds, setPounds] = useState("");
  const [pence, setPence] = useState("00");

  const { data, setData, post, processing, errors, reset } = useForm({
    title: "",
    description: "",
    location_id: "",
    start_date: new Date(),
    trade_ids: [],
    end_date: new Date(),
    dates_type: "fixed",
    interval: "daily",
    rate: "",
    qualification_ids: [],
    negotiable: "no",
  });

  useEffect(() => {
    setData("rate", pounds + "." + pence);
  }, [pounds, pence]);

  function submit(e) {
    e.preventDefault();
    post("/jobs");
  }

  return (
    <>
      <div className="overflow-hidden bg-white">
        <form
          onSubmit={submit}
          className="space-y-8 divide-y divide-gray-200 py-2"
        >
          <div className="space-y-8 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Job Details
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  We just need some basic information about the job to get
                  going.
                </p>
              </div>

              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Job Title
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Job title..."
                        onChange={(e) => setData("title", e.target.value)}
                        value={data.title}
                        autoComplete="title"
                        className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Job Description
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <textarea
                      id="about"
                      name="about"
                      rows={3}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                      onChange={(e) => setData("description", e.target.value)}
                      value={data.description}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Write a few sentences about the job.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10 sm:border-t sm:border-gray-200">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Dates
                </h3>
              </div>
              <div className="space-y-6 sm:space-y-5 sm:border-t sm:border-gray-200">
                <div className="pt-6 sm:pt-5">
                  <div role="group" aria-labelledby="label-notifications">
                    <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                      <div>
                        <div
                          className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                          id="label-notifications"
                        >
                          What type of employment is the job?
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="max-w-lg">
                          <p className="text-sm text-gray-500">
                            On-going would be constant employment
                          </p>
                          <div className="mt-4 space-y-4">
                            <div className="flex items-center">
                              <input
                                id="fixed"
                                name="dates_type"
                                value="fixed"
                                checked={data.dates_type == "fixed"}
                                type="radio"
                                onChange={(e) =>
                                  setData("dates_type", e.target.value)
                                }
                                className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                              <label
                                htmlFor="fixed"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                Fixed
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                id="ongoing"
                                name="dates_type"
                                checked={data.dates_type == "ongoing"}
                                value="ongoing"
                                type="radio"
                                onChange={(e) =>
                                  setData("dates_type", e.target.value)
                                }
                                className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                              <label
                                htmlFor="ongoing"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                Ongoing
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                id="unknown"
                                name="dates_type"
                                value="unknown"
                                checked={data.dates_type == "unknown"}
                                type="radio"
                                onChange={(e) =>
                                  setData("dates_type", e.target.value)
                                }
                                className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                              />
                              <label
                                htmlFor="unknown"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                Unknown
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="start-date"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Start Date
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                      selected={data.start_date}
                      onChange={(date) => setData("start_date", date)}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="end-date"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    End Date
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                      selected={data.end_date}
                      onChange={(date) => setData("end_date", date)}
                    />
                  </div>
                </div>
              </div>
              <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10 sm:border-t sm:border-gray-200">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Job Requirements
                  </h3>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Where is the job located?
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <LocationComponent
                      locations={locations}
                      onLocationChange={(loc) => setData("location_id", loc.id)}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="trades"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Trades
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="max-w-lg">
                      <Select
                        options={trades}
                        getOptionValue={({ id }) => id}
                        getOptionLabel={({ name }) => name}
                        styles={SelectStyles}
                        // value={location ? location : null}
                        onChange={(e) => setData("trade_ids", [e.id])}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Qualifications
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <p className="text-sm text-gray-500 my-2">
                      Select as many qualifications as required.
                    </p>
                    <div className="max-w-lg">
                      <Select
                        options={qualifications}
                        isMulti={true}
                        styles={SelectStyles}
                        getOptionValue={({ id }) => id}
                        getOptionLabel={({ name }) => name}
                        // value={location ? location : null}
                        onChange={(e) =>
                          setData(
                            "qualification_ids",
                            e.map((q) => q.id)
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10 sm:border-t sm:border-gray-200">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Job Payment
                  </h3>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Interval
                  </label>
                  <div className="max-w-lg">
                    <div className="mt-4 space-y-4">
                      <div className="flex items-center">
                        <input
                          id="daily"
                          name="interval"
                          value="daily"
                          type="radio"
                          checked={data.interval == "daily"}
                          onChange={(e) =>
                            setData({
                              ...data,
                              interval: e.target.value,
                              rate: "",
                            })
                          }
                          className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                        />
                        <label
                          htmlFor="daily"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Daily
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="hourly"
                          name="interval"
                          value="hourly"
                          type="radio"
                          checked={data.interval == "hourly"}
                          onChange={(e) =>
                            setData({
                              ...data,
                              interval: e.target.value,
                              rate: "",
                            })
                          }
                          className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                        />
                        <label
                          htmlFor="hourly"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Hourly
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="yearly"
                          name="interval"
                          value="yearly"
                          type="radio"
                          checked={data.interval == "yearly"}
                          onChange={(e) =>
                            setData({
                              ...data,
                              interval: e.target.value,
                              rate: "",
                            })
                          }
                          className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                        />
                        <label
                          htmlFor="yearly"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Annual Salary
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Rate
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    {data.interval == "daily" ? (
                      <div className="relative mt-1">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          £
                        </div>
                        <input
                          type="number"
                          name="daily"
                          id="daily"
                          onChange={(e) => setData("rate", e.target.value)}
                          className="block w-full max-w-lg shadow-sm rounded-md border-gray-300 pl-10 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                        />
                      </div>
                    ) : (
                      <div className="flex flex-row items-end">
                        <div className="relative mt-1">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            £
                          </div>
                          <input
                            type="number"
                            name="hourly_pounds"
                            id="hourly_pounds"
                            value={pounds}
                            onChange={(e) => setPounds(e.target.value)}
                            className="block w-full max-w-lg shadow-sm rounded-md border-gray-300 pl-10 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                          />
                        </div>
                        <span className="px-1.5">.</span>
                        <div className="relative mt-1">
                          <input
                            type="number"
                            name="hourly_pence"
                            id="hourly_pence"
                            value={pence}
                            onChange={(e) => setPence(e.target.value)}
                            className="block w-full max-w-lg shadow-sm rounded-md border-gray-300 pl-3 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 md:pr-8">
                            p
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Is this rate negotiable?
                  </label>
                  <div className="max-w-lg">
                    <div className="mt-4 space-y-4">
                      <div className="flex items-center">
                        <input
                          id="yes"
                          name="negotiable"
                          value="yes"
                          type="radio"
                          checked={data.negotiable == "yes"}
                          onChange={(e) =>
                            setData("negotiable", e.target.value)
                          }
                          className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                        />
                        <label
                          htmlFor="yes"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="no"
                          name="negotiable"
                          value="no"
                          type="radio"
                          checked={data.negotiable == "no"}
                          onChange={(e) =>
                            setData("negotiable", e.target.value)
                          }
                          className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-500"
                        />
                        <label
                          htmlFor="no"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                disabled={processing}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

CreateJob.layout = (page) => <Layout children={page} title="Create Job" />;

export default CreateJob;
