import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { useForm } from "@inertiajs/inertia-react";
import Select from "react-select";
import { SelectStyles } from "../styles/ReactSelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import { XCircleIcon } from "@heroicons/react/20/solid";

export const LocationComponent = (props) => {
  const [open, setOpen] = useState(false);
  const [postCode, setPostCode] = useState("");
  const [postCodeError, setPostCodeError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [location, setLocation] = useState(
    props.location ? props.location : null
  );
  const [locations, setLocations] = useState(props.locations);

  const handleChange = (event) => {
    const result = event.target.value.toUpperCase();

    setPostCode(result);
  };

  const { data, setData, errors, reset, post } = useForm({
    name: "",
    address_line1: "",
    address_line2: "",
    city: "",
    county: "",
    post_code: "",
    latitude: "",
    longitude: "",
  });

  const submitForm = (e) => {
    e.preventDefault();

    setNameError(false);

    axios({
      url: `/locations`,
      method: "POST",
      data: data,
    })
      .then((response) => {
        console.log("Res", response.data);
        reset();
        setLat(null);
        setLng(null);
        setOpen(false);
        setLocations((l) => [...l, response.data]);
        setLocation(response.data);
        props.onLocationChange(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        setNameError(true);
      });
  };

  // const formik = useFormik({
  //   initialValues: {
  //     name: "",
  //   },
  //   enableReinitialize: true,
  //   validationSchema: formValidation,
  //   onSubmit: async (values, { resetForm }) => {
  //     // handle form submission
  //     await axios({
  //       url: `/locations`,
  //       method: "POST",
  //       data: data,
  //     })
  //       .then((response) => {
  //         resetForm;
  //         setLat(null);
  //         setLng(null);
  //         reset();
  //         setOpen(false);
  //         setLocations((l) => [...l, response.data]);
  //         setLocation(response.data);
  //         props.onLocationChange(response.data);
  //         formik.setValues({
  //           ...formik.values,
  //           name: null,
  //         });
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   },
  // });

  const getPostCode = () => {
    setPostCodeError(false);
    axios
      .get("/locations/postcode_autocomplete", {
        params: { q: postCode },
      })
      .then((response) => {
        if (response.data.latitude == 0 && response.data.longitude == 0) {
          setPostCodeError(true);
          reset();
          setLat(null);
          setLng(null);
        } else {
          setLat(response.data.latitude);
          setLng(response.data.longitude);
          setData({
            address_line1: response.data.line1,
            address_line2: response.data.line2,
            city: response.data.city,
            county: response.data.county,
            post_code: postCode,
            latitude: response.data.latitude,
            longitude: response.data.longitude,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);

        setPostCodeError(true);
      });
  };

  const cancelButtonRef = useRef(null);

  return (
    <>
      <div className="max-w-lg">
        <Select
          options={locations}
          getOptionValue={({ id }) => id}
          getOptionLabel={({ name }) => name}
          value={location ? location : null}
          styles={SelectStyles}
          onChange={(e) => {
            setLocation(e);
            props.onLocationChange(e);
          }}
        />
      </div>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="inline-flex items-center mt-3 rounded-md border border-transparent w-full bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
      >
        Add a location
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div>
                    <div className="text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-medium leading-6 text-gray-900"
                      >
                        Create Location
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Please enter the post code of the location to start
                        </p>
                      </div>
                      <div className="pb-4">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                          <label
                            htmlFor="post-code"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-1"
                          >
                            Post Code
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              name="post-code"
                              onChange={handleChange}
                              value={postCode}
                              placeholder="Search for post code..."
                              id="post-code"
                              autoComplete="post-code"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="mt-3.5">
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                            onClick={() => getPostCode()}
                          >
                            Search
                          </button>
                        </div>

                        {postCodeError && (
                          <div className="rounded-md bg-red-50 p-4 mt-4">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <XCircleIcon
                                  className="h-5 w-5 text-red-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">
                                  Please check post code and try again.
                                </h3>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {data.latitude && data.longitude && (
                        <div className="border-t border-gray-200 pb-3">
                          <form>
                            <div>
                              <label
                                htmlFor="address_1"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-1"
                              >
                                Location Reference
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="name"
                                  value={data.name}
                                  onChange={(e) => {
                                    // formik.setValues({
                                    // ...formik.values,
                                    //   name: e.target.value,
                                    // });
                                    setData("name", e.target.value);
                                  }}
                                  // onBlur={formik.handleBlur}
                                  id="name"
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                                />
                              </div>
                              {nameError && (
                                <p className="text-sm font-base text-red-800 mt-2">
                                  Location reference is required
                                </p>
                              )}
                            </div>
                            <div>
                              <label
                                htmlFor="address_1"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-1"
                              >
                                Address Line One
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="address_1"
                                  required
                                  onChange={(e) =>
                                    setData("address_line1", e.target.value)
                                  }
                                  value={data.address_line1}
                                  id="address_1"
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="address_2"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-1"
                              >
                                Address Line Two
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="address_2"
                                  onChange={(e) =>
                                    setData("address_line2", e.target.value)
                                  }
                                  value={data.address_line2}
                                  id="address_2"
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="city"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-1"
                              >
                                City
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="address_2"
                                  onChange={(e) =>
                                    setData("city", e.target.value)
                                  }
                                  value={data.city}
                                  required
                                  id="address_2"
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="county"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-1"
                              >
                                County
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="county"
                                  onChange={(e) =>
                                    setData("county", e.target.value)
                                  }
                                  value={data.county}
                                  id="county"
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="post_code"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-1"
                              >
                                Post Code
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="post_code"
                                  required
                                  onChange={(e) =>
                                    setData("post_code", e.target.value)
                                  }
                                  value={data.post_code}
                                  id="post_code"
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={(e) => submitForm(e)}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default LocationComponent;
