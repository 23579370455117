import React from "react";
import Layout from "../../Layouts/Layout";
import {
  CalendarIcon,
  MapPinIcon,
  BriefcaseIcon,
  CurrencyPoundIcon,
} from "@heroicons/react/20/solid";
import Pagination from "../../Components/Pagniation";
import moment from "moment";

const Index = ({ contractors, count }) => {
  return (
    <>
      <div className="overflow-hidden bg-white shadow sm:rounded-md border-gray-200 border">
        <ul role="list" className="divide-y divide-gray-200">
          {contractors.map((provider) => (
            <li key={provider?.id}>
              <a
                className="block hover:bg-gray-50"
                href={"/contractors/" + provider?.id}
              >
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="truncate text-base font-bold text-gray-800">
                      {provider?.organization}
                    </p>
                    <div className="ml-2 flex flex-shrink-0">
                      <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                        Contact: {provider?.contact_number}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        {" "}
                        Contact: {provider?.user?.name}
                      </p>
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0"></div>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/* <Pagination total={count} /> */}
    </>
  );
};

const primary_button = {
  name: "View recruiters on map",
  link: "/contractors/map",
};

Index.layout = (page) => (
  <Layout children={page} title="Recruiters" primary_button={primary_button} />
);

export default Index;
