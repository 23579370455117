import React from "react";

const urlGenration = (encodedUrl) => {
  const decodedURL = decodeURIComponent(encodedUrl);
  const substrings = decodedURL.split('https://');
  const count = substrings[0].length === 0 ? substrings.length - 1 : substrings.length;
  var newURL = decodedURL;
  if(count >= 2){
    newURL = newURL.replace('https://advertapproval.s3.eu-central-1.amazonaws.com/uploads/','');
    newURL = newURL.replace('https://thetradesmanapp.s3.eu-west-1.amazonaws.com/uploads/','');
    newURL = newURL.replace('https://thetradesmanapp.s3.amazonaws.com/uploads/','');
  }
  return newURL;
}


function ImageGallery({ images, deleteFile }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      {images.length ? (
        <ul
          role="list"
          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 mb-6 w-full"
        >
          {images.map((file, index) => (
            <>
              {!file.deleted && (
                <li className="relative">
                  <div
                    className={classNames(
                      file.current
                        ? "ring-2 ring-offset-2 ring-orange-500"
                        : "focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-orange-500",
                      "group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden"
                    )}
                  >
                    {file.temp_url ? (
                      <img
                        src={file.temp_url}
                        alt=""
                        className={classNames(
                          file.current ? "" : "group-hover:opacity-75",
                          "object-cover pointer-events-none"
                        )}
                      />
                    ) : (
                      <img
                        src={urlGenration(file.image.url)}
                        alt=""
                        className={classNames(
                          file.current ? "" : "group-hover:opacity-75",
                          "object-cover pointer-events-none"
                        )}
                      />
                    )}
                    <button
                      type="button"
                      className="absolute inset-0 focus:outline-none"
                    >
                      <span className="sr-only">
                        View details for {file.name}
                      </span>
                    </button>
                  </div>
                  <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                    {file.name}
                  </p>
                  <button
                    type="button"
                    className="text-red-500 text-sm font-bold"
                    onClick={() => deleteFile(index)}
                  >
                    DELETE
                  </button>
                </li>
              )}
            </>
          ))}
        </ul>
      ) : null}
    </>
  );
}
export default ImageGallery;
