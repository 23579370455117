import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BuildingOffice2Icon,
  Cog6ToothIcon,
  FolderIcon,
  HomeIcon,
  UserCircleIcon,
  UserIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
  CreditCardIcon,
} from "@heroicons/react/24/outline";
import { usePage } from "@inertiajs/inertia-react";
import NoticeMessage from "../Components/Flash/NoticeMessage";
import Errors from "../Components/Errors";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout({
  children,
  title,
  primary_button,
  secondary_button,
}) {
  const { user, thumbnail } = usePage().props;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigation, setNavigation] = useState(
    user?.main_account_type == "recruiter"
      ? [
          {
            name: "Recruiters",
            href: "/job_owners?page=1",
            icon: FolderIcon,
            current: window.location.pathname.includes("job_owners"),
          },
          {
            name: "Construction Courses",
            href: "/training?page=1",
            icon: UsersIcon,
            current: false,
          },
          {
            name: "Accommodation",
            href: "/accommodation?page=1",
            icon: HomeIcon,
            current: window.location.pathname.includes("accommodation"),
          },
          {
            name: "Profile",
            href: "/profile",
            icon: UserIcon,
            current: window.location.pathname.includes("profile"),
          },
          {
            name: "Subscriptions",
            href: "/subscriptions",
            icon: CreditCardIcon,
            current: window.location.pathname.includes("subscriptions"),
          },
          {
            name: "Settings",
            href: "/settings",
            icon: Cog6ToothIcon,
            current: window.location.pathname.includes("Settings"),
          },
        ]
      : user?.main_account_type == "job_seeker"
      ? [
          {
            name: "Jobs",
            href: "/jobs?page=1",
            icon: WrenchScrewdriverIcon,
            current: window.location.pathname.includes("jobs"),
          },
          {
            name: "Construction Courses",
            href: "/training?page=1",
            icon: UsersIcon,
            current: false,
          },
          {
            name: "Accommodation",
            href: "/accommodation?page=1",
            icon: HomeIcon,
            current: window.location.pathname.includes("accommodation"),
          },
          {
            name: "Profile",
            href: "/profile",
            icon: UserIcon,
            current: window.location.pathname.includes("profile"),
          },
          {
            name: "Settings",
            href: "/settings",
            icon: Cog6ToothIcon,
            current: window.location.pathname.includes("Settings"),
          },
        ]
      : user?.main_account_type == "contractor"
      ? [
          {
            name: "Contractors",
            href: "/contractors",
            icon: BuildingOffice2Icon,
            current: window.location.pathname.includes("contractor"),
          },
          {
            name: "Construction Courses",
            href: "/training?page=1",
            icon: UsersIcon,
            current: window.location.pathname.includes("training"),
          },
          {
            name: "Accommodation",
            href: "/accommodation?page=1",
            icon: HomeIcon,
            current: window.location.pathname.includes("accommodation"),
          },
          {
            name: "Profile",
            href: "/profile",
            icon: UserIcon,
            current: window.location.pathname.includes("profile"),
          },
        ]
      : [
          {
            name: "Construction Courses",
            href: "/training?page=1",
            icon: UsersIcon,
            current: false,
          },
          {
            name: "Accommodation",
            href: "/accommodation?page=1",
            icon: HomeIcon,
            current: window.location.pathname.includes("accommodation"),
          },
          {
            name: "Profile",
            href: "/profile",
            icon: UserIcon,
            current: window.location.pathname.includes("profile"),
          },
        ]
    // { name: "Settings", href: "#", icon: Cog6ToothIcon, current: false },
  );

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html className="h-full bg-gray-100">
        <body className="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full border-2 border-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      {/* <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=orange&shade=500"
                        alt="Your Company"
                      /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 2259.39 412.98"
                        className="h-8 w-auto"
                      >
                        <defs></defs>
                        <g
                          id="b8e1d5b7-aaf0-4676-9763-852b17e48fce"
                          data-name="Layer 2"
                        >
                          <g
                            id="e7b7cd1a-d1ad-47d9-aef6-114191f403d9"
                            data-name="Layer 1"
                          >
                            <path
                              className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                              d="M476.23,190.57,152.59,3.73C137.46-5,125.07,2.14,125.07,19.63v132.8h246.5v53.75H270.89V341L476.23,222.37c15.17-8.75,15.17-23.06,0-31.8"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(254, 123, 8)"
                            ></path>
                            <path
                              className="b52771fa-6811-43bf-9f46-9c189a729217"
                              d="M0,206.13s98.66-3.7,131.6,70.8c22.34,50.48-6.53,116.43-6.53,116.43,0,17.48,12.39,24.64,27.52,15.89l50-28.86V206.13Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(51, 127, 166)"
                            ></path>
                            <path
                              className="b52771fa-6811-43bf-9f46-9c189a729217"
                              d="M630,181.9v76.45q0,3.86-2.26,5.06a14.61,14.61,0,0,1-6.66,1.2,14.26,14.26,0,0,1-6.53-1.2q-2.26-1.2-2.26-5.06V181.9H572.15c-2.13,0-3.53-.6-4.19-1.8a15,15,0,0,1,0-10.78c.66-1.2,2.06-1.8,4.19-1.8h98q3.2,0,4.2,1.8a15,15,0,0,1,0,10.78q-1,1.8-4.2,1.8Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(51, 127, 166)"
                            ></path>
                            <path
                              className="b52771fa-6811-43bf-9f46-9c189a729217"
                              d="M691.61,264.61q-4.26,0-6.32-1.2t-2.07-5.06v-85.1q0-3.87,2.07-5.07a13.05,13.05,0,0,1,6.32-1.19,13.55,13.55,0,0,1,6.4,1.19q2.13,1.2,2.13,5.07v15.58h51.14Q789,188.83,789,212v46.35q0,3.86-2.13,5.06a13.41,13.41,0,0,1-6.4,1.2q-4.26,0-6.32-1.2t-2.07-5.06V212c0-2.75-1.68-5.06-5.06-6.92s-8.79-2.8-16.24-2.8H700.14v56.07q0,3.86-2.13,5.06A13.41,13.41,0,0,1,691.61,264.61Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(51, 127, 166)"
                            ></path>
                            <path
                              className="b52771fa-6811-43bf-9f46-9c189a729217"
                              d="M816.67,234.11v6.79c0,2.66,1.68,4.95,5.06,6.86s8.79,2.86,16.25,2.86h15.31c2,0,3.35.56,3.93,1.67a11.13,11.13,0,0,1,.87,5,11.59,11.59,0,0,1-.87,5.06c-.58,1.16-1.89,1.73-3.93,1.73H837.44q-37.68,0-37.69-23.17V212q0-23.17,37.69-23.17h30.77q20,0,28.83,5.72T905.9,212v15.32c0,2.57-.49,4.35-1.47,5.32s-3.15,1.47-6.52,1.47Zm0-13.19H889V212q0-4.67-5-7.19t-16.31-2.53H838q-11.19,0-16.25,2.8c-3.38,1.86-5.06,4.17-5.06,6.92Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(51, 127, 166)"
                            ></path>
                            <path
                              className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                              d="M961.17,181.9v76.45q0,3.86-2.27,5.06a14.61,14.61,0,0,1-6.66,1.2,14.21,14.21,0,0,1-6.52-1.2q-2.26-1.2-2.27-5.06V181.9H903.37q-3.2,0-4.2-1.8a15,15,0,0,1,0-10.78q1-1.8,4.2-1.8h98c2.13,0,3.53.6,4.19,1.8a15,15,0,0,1,0,10.78c-.66,1.2-2.06,1.8-4.19,1.8Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(254, 123, 8)"
                            ></path>
                            <path
                              className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                              d="M1048.13,188.83c2,0,3.35.58,3.93,1.73a11.59,11.59,0,0,1,.87,5.06,11.1,11.1,0,0,1-.87,5c-.58,1.12-1.88,1.67-3.93,1.67h-10.38q-9.87,0-13.72,2.53a8,8,0,0,0-3.86,7.06v46.48c0,2.57-.72,4.26-2.13,5.06a13.44,13.44,0,0,1-6.4,1.2q-4.26,0-6.32-1.2t-2.07-5.06V211.87q0-11.6,8.86-17.32t25.64-5.72Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(254, 123, 8)"
                            ></path>
                            <path
                              className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                              d="M1079.16,264.07q-37.68,0-37.69-23.17v-7.06q0-23.18,37.69-23.17h57v-.13q0-3.87-5-6.06t-16.31-2.2h-12.52c-2,0-3.38-.55-4-1.67a10.48,10.48,0,0,1-.93-5,10.9,10.9,0,0,1,.93-5.13c.62-1.11,2-1.66,4-1.66h13.05q37.68,0,37.69,23.17v28.9q0,23.18-37.69,23.17Zm35.7-13.45q11.17,0,16.24-2.86c3.38-1.91,5.06-4.2,5.06-6.86V223.59H1079.7q-11.19,0-16.25,2.79c-3.38,1.87-5.06,4.18-5.06,6.93v7.59c0,2.66,1.68,4.95,5.06,6.86s8.79,2.86,16.25,2.86Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(254, 123, 8)"
                            ></path>
                            <path
                              className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                              d="M1253.63,173.25q0-3.87,2.13-5.07a13.5,13.5,0,0,1,6.39-1.19,13.13,13.13,0,0,1,6.33,1.19q2.05,1.2,2.06,5.07V240.9q0,23.18-37.69,23.17h-32q-37.7,0-37.69-23.17V212q0-23.17,37.69-23.17h52.74Zm-52.21,29q-11.19,0-16.25,2.8t-5.06,6.92v28.9q0,4,5.06,6.86t16.25,2.86h30.9q11.19,0,16.25-2.86t5.06-6.86V202.28Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(254, 123, 8)"
                            ></path>
                            <path
                              className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                              d="M1298.24,234.11v6.79q0,4,5.06,6.86t16.25,2.86h15.31c2.05,0,3.36.56,3.93,1.67a11.13,11.13,0,0,1,.87,5,11.59,11.59,0,0,1-.87,5.06c-.57,1.16-1.88,1.73-3.93,1.73H1319q-37.69,0-37.69-23.17V212q0-23.17,37.69-23.17h30.76q20,0,28.83,5.72t8.86,17.45v15.32c0,2.57-.49,4.35-1.46,5.32s-3.16,1.47-6.53,1.47Zm0-13.19h72.32V212q0-4.67-5-7.19t-16.31-2.53h-29.7q-11.19,0-16.25,2.8t-5.06,6.92Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(254, 123, 8)"
                            ></path>
                            <path
                              className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                              d="M1396,208.8q0-9.31,8-14.64t23.71-5.33h18.25c2,0,3.37.55,4,1.66a10.9,10.9,0,0,1,.93,5.13,10.48,10.48,0,0,1-.93,5c-.62,1.12-2,1.67-4,1.67h-18.38q-7.59,0-11.19,1.8t-3.6,4.86v3.46c0,2,1.25,3.69,3.73,4.93s6.4,1.86,11.72,1.86h29.44q18.63,0,26.9,5.33t8.26,14.92v4.26a16.32,16.32,0,0,1-8.79,15,38.71,38.71,0,0,1-11.72,4,91.72,91.72,0,0,1-17.19,1.39h-53.93c-2,0-3.38-.55-4-1.66a10.9,10.9,0,0,1-.93-5.13,10.5,10.5,0,0,1,.93-5c.62-1.11,2-1.67,4-1.67h56.47a69.76,69.76,0,0,0,8.85-.46,22.93,22.93,0,0,0,5.6-1.33,6.24,6.24,0,0,0,2.86-2.07,5,5,0,0,0,.8-2.8v-4.79a5,5,0,0,0-.8-2.8,6.61,6.61,0,0,0-2.8-2.13,19.58,19.58,0,0,0-5.39-1.4,65.48,65.48,0,0,0-8.59-.46h-29.83q-16.12,0-24.24-5.26T1396,212.67Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(254, 123, 8)"
                            ></path>
                            <path
                              className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                              d="M1638.64,264.61a13.37,13.37,0,0,1-6.39-1.2q-2.13-1.2-2.13-5.06V212q0-4.12-4.53-6.92t-15.71-2.8h-7.46a48.38,48.38,0,0,0-9.26.73,19.13,19.13,0,0,0-5.92,2.07,8,8,0,0,0-3.2,3.13,8.11,8.11,0,0,0-.93,3.79v46.35q0,3.86-2.13,5.06a13.38,13.38,0,0,1-6.4,1.2c-3,0-5.17-.45-6.45-1.34s-1.94-2.53-1.94-4.92V212a8.11,8.11,0,0,0-.93-3.79,8,8,0,0,0-3.19-3.13,19.2,19.2,0,0,0-5.93-2.07,48.38,48.38,0,0,0-9.26-.73h-7.45q-11.19,0-15.72,2.8t-4.53,6.92v46.35q0,3.86-2.13,5.06a13.37,13.37,0,0,1-6.39,1.2,13,13,0,0,1-6.33-1.2c-1.38-.8-2.06-2.49-2.06-5.06V212q0-11.72,9-17.45t25.24-5.72h13.18q16.78,0,24.9,6.66,8.26-6.66,25-6.66h13.19q16.25,0,25.23,5.72t9,17.45v46.35c0,2.57-.68,4.26-2.06,5.06A13,13,0,0,1,1638.64,264.61Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(254, 123, 8)"
                            ></path>
                            <path
                              className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                              d="M1694.71,264.07q-37.7,0-37.69-23.17v-7.06q0-23.18,37.69-23.17h57v-.13q0-3.87-5-6.06t-16.32-2.2h-12.52c-2,0-3.37-.55-4-1.67a10.48,10.48,0,0,1-.93-5,10.9,10.9,0,0,1,.93-5.13c.62-1.11,1.95-1.66,4-1.66h13.06q37.68,0,37.69,23.17v28.9q0,23.18-37.69,23.17Zm35.69-13.45q11.19,0,16.25-2.86t5.06-6.86V223.59h-56.47q-11.17,0-16.24,2.79c-3.38,1.87-5.06,4.18-5.06,6.93v7.59c0,2.66,1.68,4.95,5.06,6.86s8.79,2.86,16.24,2.86Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(254, 123, 8)"
                            ></path>
                            <path
                              className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                              d="M1787.8,264.61q-4.26,0-6.32-1.2t-2.07-5.06V212q0-23.17,37.69-23.17h29q37.69,0,37.69,23.17v46.35q0,3.86-2.13,5.06a13.37,13.37,0,0,1-6.39,1.2,13,13,0,0,1-6.33-1.2q-2.06-1.2-2.06-5.06V212q0-4.12-5.06-6.92t-16.25-2.8h-28q-11.18,0-16.24,2.8t-5.07,6.92v46.35q0,3.86-2.13,5.06A13.37,13.37,0,0,1,1787.8,264.61Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(254, 123, 8)"
                            ></path>
                            <path
                              className="b52771fa-6811-43bf-9f46-9c189a729217"
                              d="M1957.2,167.12a14.46,14.46,0,0,1,6.33,1.2,10.52,10.52,0,0,1,4.06,3.46l58.07,82.84a5.64,5.64,0,0,1,.93,3.06,5.11,5.11,0,0,1-.86,2.8,7.88,7.88,0,0,1-2.27,2.26,12.18,12.18,0,0,1-3.13,1.47,11.87,11.87,0,0,1-3.46.53,8.71,8.71,0,0,1-3.33-.6,5.9,5.9,0,0,1-2.53-2.06l-15.72-22.91h-74l-14.79,22.91a5.08,5.08,0,0,1-2.46,2.06,8.86,8.86,0,0,1-3.26.6,11.43,11.43,0,0,1-3.33-.53,11,11,0,0,1-3.13-1.53,9.23,9.23,0,0,1-2.27-2.27,5,5,0,0,1-.86-2.86,5.9,5.9,0,0,1,.93-2.93l54.6-82.84a9.5,9.5,0,0,1,4-3.46A15.25,15.25,0,0,1,1957.2,167.12Zm-26.76,57.67h55l-28.24-41.42Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(51, 127, 166)"
                            ></path>
                            <path
                              className="b52771fa-6811-43bf-9f46-9c189a729217"
                              d="M2050.83,280.19q0,3.85-2.13,5.06a13.41,13.41,0,0,1-6.4,1.2q-4.26,0-6.32-1.2t-2.07-5.06V212q0-23.17,37.69-23.17h32q37.68,0,37.69,23.17v28.9q0,23.18-37.69,23.17h-52.74Zm52.2-29.57q11.19,0,16.25-2.79t5.06-6.93V212q0-4-5.06-6.86T2103,202.28h-30.89q-11.19,0-16.25,2.86c-3.38,1.91-5.06,4.2-5.06,6.86v38.62Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(51, 127, 166)"
                            ></path>
                            <path
                              className="b52771fa-6811-43bf-9f46-9c189a729217"
                              d="M2169,280.19c0,2.57-.72,4.26-2.13,5.06a13.44,13.44,0,0,1-6.4,1.2q-4.26,0-6.32-1.2t-2.07-5.06V212q0-23.17,37.69-23.17h32q37.7,0,37.7,23.17v28.9q0,23.18-37.7,23.17H2169Zm52.2-29.57q11.19,0,16.25-2.79t5.06-6.93V212q0-4-5.06-6.86t-16.25-2.86h-30.89q-11.19,0-16.25,2.86c-3.38,1.91-5.06,4.2-5.06,6.86v38.62Z"
                              stroke="none"
                              strokeWidth="1px"
                              strokeLinecap="butt"
                              strokeDasharray="none"
                              strokeMiterlimit="4"
                              fill="rgb(51, 127, 166)"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-300"
                                : "text-gray-400 group-hover:text-gray-300",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                  <div className="flex flex-shrink-0 bg-gray-700 p-4">
                    <div className="group block w-full flex-shrink-0">
                      <div className="flex items-center">
                        <a href="/profile">
                          {thumbnail ? (
                            <img
                              className="inline-block h-9 w-9 rounded-full object-cover"
                              src={thumbnail}
                              alt={user?.name + " Avatar"}
                            />
                          ) : (
                            <UserCircleIcon
                              className="h-9 w-9 text-gray-400"
                              aria-hidden="true"
                            />
                          )}
                        </a>
                        <div className="ml-3 flex flex-col">
                          <a
                            href="/profile"
                            className="text-sm font-medium text-white"
                          >
                            {user?.name}
                          </a>
                          <form action="/signout" method="POST">
                            <button type="submit" className="text-xs font-medium text-gray-300 group-hover:text-gray-200 mt-0.5">
                              Sign out
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                {/* <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=orange&shade=500"
                  alt="Your Company"
                /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 2259.39 412.98"
                  className="h-8 w-auto"
                >
                  <defs></defs>
                  <g
                    id="b8e1d5b7-aaf0-4676-9763-852b17e48fce"
                    data-name="Layer 2"
                  >
                    <g
                      id="e7b7cd1a-d1ad-47d9-aef6-114191f403d9"
                      data-name="Layer 1"
                    >
                      <path
                        className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                        d="M476.23,190.57,152.59,3.73C137.46-5,125.07,2.14,125.07,19.63v132.8h246.5v53.75H270.89V341L476.23,222.37c15.17-8.75,15.17-23.06,0-31.8"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(254, 123, 8)"
                      ></path>
                      <path
                        className="b52771fa-6811-43bf-9f46-9c189a729217"
                        d="M0,206.13s98.66-3.7,131.6,70.8c22.34,50.48-6.53,116.43-6.53,116.43,0,17.48,12.39,24.64,27.52,15.89l50-28.86V206.13Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(51, 127, 166)"
                      ></path>
                      <path
                        className="b52771fa-6811-43bf-9f46-9c189a729217"
                        d="M630,181.9v76.45q0,3.86-2.26,5.06a14.61,14.61,0,0,1-6.66,1.2,14.26,14.26,0,0,1-6.53-1.2q-2.26-1.2-2.26-5.06V181.9H572.15c-2.13,0-3.53-.6-4.19-1.8a15,15,0,0,1,0-10.78c.66-1.2,2.06-1.8,4.19-1.8h98q3.2,0,4.2,1.8a15,15,0,0,1,0,10.78q-1,1.8-4.2,1.8Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(51, 127, 166)"
                      ></path>
                      <path
                        className="b52771fa-6811-43bf-9f46-9c189a729217"
                        d="M691.61,264.61q-4.26,0-6.32-1.2t-2.07-5.06v-85.1q0-3.87,2.07-5.07a13.05,13.05,0,0,1,6.32-1.19,13.55,13.55,0,0,1,6.4,1.19q2.13,1.2,2.13,5.07v15.58h51.14Q789,188.83,789,212v46.35q0,3.86-2.13,5.06a13.41,13.41,0,0,1-6.4,1.2q-4.26,0-6.32-1.2t-2.07-5.06V212c0-2.75-1.68-5.06-5.06-6.92s-8.79-2.8-16.24-2.8H700.14v56.07q0,3.86-2.13,5.06A13.41,13.41,0,0,1,691.61,264.61Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(51, 127, 166)"
                      ></path>
                      <path
                        className="b52771fa-6811-43bf-9f46-9c189a729217"
                        d="M816.67,234.11v6.79c0,2.66,1.68,4.95,5.06,6.86s8.79,2.86,16.25,2.86h15.31c2,0,3.35.56,3.93,1.67a11.13,11.13,0,0,1,.87,5,11.59,11.59,0,0,1-.87,5.06c-.58,1.16-1.89,1.73-3.93,1.73H837.44q-37.68,0-37.69-23.17V212q0-23.17,37.69-23.17h30.77q20,0,28.83,5.72T905.9,212v15.32c0,2.57-.49,4.35-1.47,5.32s-3.15,1.47-6.52,1.47Zm0-13.19H889V212q0-4.67-5-7.19t-16.31-2.53H838q-11.19,0-16.25,2.8c-3.38,1.86-5.06,4.17-5.06,6.92Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(51, 127, 166)"
                      ></path>
                      <path
                        className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                        d="M961.17,181.9v76.45q0,3.86-2.27,5.06a14.61,14.61,0,0,1-6.66,1.2,14.21,14.21,0,0,1-6.52-1.2q-2.26-1.2-2.27-5.06V181.9H903.37q-3.2,0-4.2-1.8a15,15,0,0,1,0-10.78q1-1.8,4.2-1.8h98c2.13,0,3.53.6,4.19,1.8a15,15,0,0,1,0,10.78c-.66,1.2-2.06,1.8-4.19,1.8Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(254, 123, 8)"
                      ></path>
                      <path
                        className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                        d="M1048.13,188.83c2,0,3.35.58,3.93,1.73a11.59,11.59,0,0,1,.87,5.06,11.1,11.1,0,0,1-.87,5c-.58,1.12-1.88,1.67-3.93,1.67h-10.38q-9.87,0-13.72,2.53a8,8,0,0,0-3.86,7.06v46.48c0,2.57-.72,4.26-2.13,5.06a13.44,13.44,0,0,1-6.4,1.2q-4.26,0-6.32-1.2t-2.07-5.06V211.87q0-11.6,8.86-17.32t25.64-5.72Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(254, 123, 8)"
                      ></path>
                      <path
                        className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                        d="M1079.16,264.07q-37.68,0-37.69-23.17v-7.06q0-23.18,37.69-23.17h57v-.13q0-3.87-5-6.06t-16.31-2.2h-12.52c-2,0-3.38-.55-4-1.67a10.48,10.48,0,0,1-.93-5,10.9,10.9,0,0,1,.93-5.13c.62-1.11,2-1.66,4-1.66h13.05q37.68,0,37.69,23.17v28.9q0,23.18-37.69,23.17Zm35.7-13.45q11.17,0,16.24-2.86c3.38-1.91,5.06-4.2,5.06-6.86V223.59H1079.7q-11.19,0-16.25,2.79c-3.38,1.87-5.06,4.18-5.06,6.93v7.59c0,2.66,1.68,4.95,5.06,6.86s8.79,2.86,16.25,2.86Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(254, 123, 8)"
                      ></path>
                      <path
                        className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                        d="M1253.63,173.25q0-3.87,2.13-5.07a13.5,13.5,0,0,1,6.39-1.19,13.13,13.13,0,0,1,6.33,1.19q2.05,1.2,2.06,5.07V240.9q0,23.18-37.69,23.17h-32q-37.7,0-37.69-23.17V212q0-23.17,37.69-23.17h52.74Zm-52.21,29q-11.19,0-16.25,2.8t-5.06,6.92v28.9q0,4,5.06,6.86t16.25,2.86h30.9q11.19,0,16.25-2.86t5.06-6.86V202.28Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(254, 123, 8)"
                      ></path>
                      <path
                        className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                        d="M1298.24,234.11v6.79q0,4,5.06,6.86t16.25,2.86h15.31c2.05,0,3.36.56,3.93,1.67a11.13,11.13,0,0,1,.87,5,11.59,11.59,0,0,1-.87,5.06c-.57,1.16-1.88,1.73-3.93,1.73H1319q-37.69,0-37.69-23.17V212q0-23.17,37.69-23.17h30.76q20,0,28.83,5.72t8.86,17.45v15.32c0,2.57-.49,4.35-1.46,5.32s-3.16,1.47-6.53,1.47Zm0-13.19h72.32V212q0-4.67-5-7.19t-16.31-2.53h-29.7q-11.19,0-16.25,2.8t-5.06,6.92Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(254, 123, 8)"
                      ></path>
                      <path
                        className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                        d="M1396,208.8q0-9.31,8-14.64t23.71-5.33h18.25c2,0,3.37.55,4,1.66a10.9,10.9,0,0,1,.93,5.13,10.48,10.48,0,0,1-.93,5c-.62,1.12-2,1.67-4,1.67h-18.38q-7.59,0-11.19,1.8t-3.6,4.86v3.46c0,2,1.25,3.69,3.73,4.93s6.4,1.86,11.72,1.86h29.44q18.63,0,26.9,5.33t8.26,14.92v4.26a16.32,16.32,0,0,1-8.79,15,38.71,38.71,0,0,1-11.72,4,91.72,91.72,0,0,1-17.19,1.39h-53.93c-2,0-3.38-.55-4-1.66a10.9,10.9,0,0,1-.93-5.13,10.5,10.5,0,0,1,.93-5c.62-1.11,2-1.67,4-1.67h56.47a69.76,69.76,0,0,0,8.85-.46,22.93,22.93,0,0,0,5.6-1.33,6.24,6.24,0,0,0,2.86-2.07,5,5,0,0,0,.8-2.8v-4.79a5,5,0,0,0-.8-2.8,6.61,6.61,0,0,0-2.8-2.13,19.58,19.58,0,0,0-5.39-1.4,65.48,65.48,0,0,0-8.59-.46h-29.83q-16.12,0-24.24-5.26T1396,212.67Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(254, 123, 8)"
                      ></path>
                      <path
                        className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                        d="M1638.64,264.61a13.37,13.37,0,0,1-6.39-1.2q-2.13-1.2-2.13-5.06V212q0-4.12-4.53-6.92t-15.71-2.8h-7.46a48.38,48.38,0,0,0-9.26.73,19.13,19.13,0,0,0-5.92,2.07,8,8,0,0,0-3.2,3.13,8.11,8.11,0,0,0-.93,3.79v46.35q0,3.86-2.13,5.06a13.38,13.38,0,0,1-6.4,1.2c-3,0-5.17-.45-6.45-1.34s-1.94-2.53-1.94-4.92V212a8.11,8.11,0,0,0-.93-3.79,8,8,0,0,0-3.19-3.13,19.2,19.2,0,0,0-5.93-2.07,48.38,48.38,0,0,0-9.26-.73h-7.45q-11.19,0-15.72,2.8t-4.53,6.92v46.35q0,3.86-2.13,5.06a13.37,13.37,0,0,1-6.39,1.2,13,13,0,0,1-6.33-1.2c-1.38-.8-2.06-2.49-2.06-5.06V212q0-11.72,9-17.45t25.24-5.72h13.18q16.78,0,24.9,6.66,8.26-6.66,25-6.66h13.19q16.25,0,25.23,5.72t9,17.45v46.35c0,2.57-.68,4.26-2.06,5.06A13,13,0,0,1,1638.64,264.61Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(254, 123, 8)"
                      ></path>
                      <path
                        className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                        d="M1694.71,264.07q-37.7,0-37.69-23.17v-7.06q0-23.18,37.69-23.17h57v-.13q0-3.87-5-6.06t-16.32-2.2h-12.52c-2,0-3.37-.55-4-1.67a10.48,10.48,0,0,1-.93-5,10.9,10.9,0,0,1,.93-5.13c.62-1.11,1.95-1.66,4-1.66h13.06q37.68,0,37.69,23.17v28.9q0,23.18-37.69,23.17Zm35.69-13.45q11.19,0,16.25-2.86t5.06-6.86V223.59h-56.47q-11.17,0-16.24,2.79c-3.38,1.87-5.06,4.18-5.06,6.93v7.59c0,2.66,1.68,4.95,5.06,6.86s8.79,2.86,16.24,2.86Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(254, 123, 8)"
                      ></path>
                      <path
                        className="a399ae67-97e6-49a5-a3c0-69b6eb420c91"
                        d="M1787.8,264.61q-4.26,0-6.32-1.2t-2.07-5.06V212q0-23.17,37.69-23.17h29q37.69,0,37.69,23.17v46.35q0,3.86-2.13,5.06a13.37,13.37,0,0,1-6.39,1.2,13,13,0,0,1-6.33-1.2q-2.06-1.2-2.06-5.06V212q0-4.12-5.06-6.92t-16.25-2.8h-28q-11.18,0-16.24,2.8t-5.07,6.92v46.35q0,3.86-2.13,5.06A13.37,13.37,0,0,1,1787.8,264.61Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(254, 123, 8)"
                      ></path>
                      <path
                        className="b52771fa-6811-43bf-9f46-9c189a729217"
                        d="M1957.2,167.12a14.46,14.46,0,0,1,6.33,1.2,10.52,10.52,0,0,1,4.06,3.46l58.07,82.84a5.64,5.64,0,0,1,.93,3.06,5.11,5.11,0,0,1-.86,2.8,7.88,7.88,0,0,1-2.27,2.26,12.18,12.18,0,0,1-3.13,1.47,11.87,11.87,0,0,1-3.46.53,8.71,8.71,0,0,1-3.33-.6,5.9,5.9,0,0,1-2.53-2.06l-15.72-22.91h-74l-14.79,22.91a5.08,5.08,0,0,1-2.46,2.06,8.86,8.86,0,0,1-3.26.6,11.43,11.43,0,0,1-3.33-.53,11,11,0,0,1-3.13-1.53,9.23,9.23,0,0,1-2.27-2.27,5,5,0,0,1-.86-2.86,5.9,5.9,0,0,1,.93-2.93l54.6-82.84a9.5,9.5,0,0,1,4-3.46A15.25,15.25,0,0,1,1957.2,167.12Zm-26.76,57.67h55l-28.24-41.42Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(51, 127, 166)"
                      ></path>
                      <path
                        className="b52771fa-6811-43bf-9f46-9c189a729217"
                        d="M2050.83,280.19q0,3.85-2.13,5.06a13.41,13.41,0,0,1-6.4,1.2q-4.26,0-6.32-1.2t-2.07-5.06V212q0-23.17,37.69-23.17h32q37.68,0,37.69,23.17v28.9q0,23.18-37.69,23.17h-52.74Zm52.2-29.57q11.19,0,16.25-2.79t5.06-6.93V212q0-4-5.06-6.86T2103,202.28h-30.89q-11.19,0-16.25,2.86c-3.38,1.91-5.06,4.2-5.06,6.86v38.62Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(51, 127, 166)"
                      ></path>
                      <path
                        className="b52771fa-6811-43bf-9f46-9c189a729217"
                        d="M2169,280.19c0,2.57-.72,4.26-2.13,5.06a13.44,13.44,0,0,1-6.4,1.2q-4.26,0-6.32-1.2t-2.07-5.06V212q0-23.17,37.69-23.17h32q37.7,0,37.7,23.17v28.9q0,23.18-37.7,23.17H2169Zm52.2-29.57q11.19,0,16.25-2.79t5.06-6.93V212q0-4-5.06-6.86t-16.25-2.86h-30.89q-11.19,0-16.25,2.86c-3.38,1.91-5.06,4.2-5.06,6.86v38.62Z"
                        stroke="none"
                        strokeWidth="1px"
                        strokeLinecap="butt"
                        strokeDasharray="none"
                        strokeMiterlimit="4"
                        fill="rgb(51, 127, 166)"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <nav className="mt-5 flex-1 space-y-1 px-2">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-300"
                          : "text-gray-400 group-hover:text-gray-300",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
            <div className="flex flex-shrink-0 bg-gray-700 p-4">
              <div className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                  <a href="/profile">
                    {thumbnail ? (
                      <img
                        className="inline-block h-9 w-9 rounded-full object-cover"
                        src={thumbnail}
                        alt={user?.name + " Avatar"}
                      />
                    ) : (
                      <UserCircleIcon
                        className="h-9 w-9 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </a>
                  <div className="ml-3 flex flex-col">
                    <a
                      href="/profile"
                      className="text-sm font-medium text-white"
                    >
                      {user?.name}
                    </a>
                    <a href="/signout" data-method="post" className="text-xs font-medium text-gray-300 group-hover:text-gray-200 mt-0.5">
                      Sign out
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden bg-gray-800">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-100 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            <div className="py-6">
              <div className="md:flex md:items-center md:justify-between max-w-7xl px-4 sm:px-6 md:px-8 mx-auto  xl:mt-10">
                <div className="min-w-0 flex-1">
                  <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    {title}
                  </h1>
                </div>
                <div className="mt-4 flex md:mt-0 md:ml-4">
                  {primary_button && (
                    <a
                      href={primary_button.link}
                      className=" inline-flex items-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                    >
                      {primary_button.name}
                    </a>
                  )}
                  {secondary_button && (
                    <a
                      href={secondary_button.link}
                      className="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                    >
                      {secondary_button.name}
                    </a>
                  )}
                </div>
              </div>

              <NoticeMessage />
              <Errors />

              <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                <div className="py-4">{children}</div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
