import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React from "react";
import CheckoutForm from "./CheckoutForm";

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const PaymentOptions = ({
  priceId,
  setOpen,
  userId,
  isCardList,
  selectedPlanName,
  setPaymentSuccessModal,
}) => {
  return (
    <>
      <Elements
        stripe={stripePromise}
        setOpen={setOpen}
        setPaymentSuccessModal={setPaymentSuccessModal}
        isCardList={isCardList}
        selectedPlanName={selectedPlanName}
        priceId={priceId}
        userId={userId}
      >
        <CheckoutForm
          setPaymentSuccessModal={setPaymentSuccessModal}
          isCardList={isCardList}
          selectedPlanName={selectedPlanName}
          setOpen={setOpen}
          priceId={priceId}
          userId={userId}
        />
      </Elements>
    </>
  );
};

export default PaymentOptions;
