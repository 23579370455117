import React from "react";
import Layout from "../../Layouts/Layout";
import {
  CreditCardIcon
} from "@heroicons/react/20/solid";
import Pagination from "../../Components/Pagniation";
import moment from "moment";

const Index = ({ providers, count, current_user }) => {
  return (
    <>
      {/* {(current_user.plan_status != "paid" || current_user?.plan_status != "trialing" || current_user?.plan_status != "active") && !current_user.subscription_id ? (
        <>
        <div className="rounded-md bg-red-50 p-4 mb-6">
          <div className="flex">
            <div className="flex-shrink-0 pt-1">
              <CreditCardIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true" />
            </div>
            <div className="ml-3 md:justify-between">
              <p className="font-bold text-red-700">You are almost there!</p>
              <p className="text-sm text-red-700">
                Please complete your account! We updated our application, so you must follow the remaining instructions. We added a one-month free trial and stopped offering unlimited free versions.
              </p>
            </div>
            <div className="flex-shrink-0 pt-5">
                <a
                  href="/subscriptions"
                  className="whitespace-nowrap font-medium text-red-700 hover:text-blue-600"
                >
                  Active
                  <span aria-hidden="true"> &rarr;</span>
                </a>
            </div>
          </div>
        </div>
        </>
      ) : null} */}
      <div className="overflow-hidden bg-white shadow sm:rounded-md border-gray-200 border">
        <ul role="list" className="divide-y divide-gray-200">
          {providers.map((provider) => (
            <li key={provider.id}>
              <a
                className="block hover:bg-gray-50"
                href={"/training/" + provider.id}
              >
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="truncate text-base font-bold text-gray-800">
                      {provider.company_name}
                    </p>
                    <div className="ml-2 flex flex-shrink-0">
                      <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                        Contact: {provider.contact_number}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        {" "}
                        Contact: {provider.user.name}
                      </p>
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0"></div>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <Pagination total={count} />
    </>
  );
};

const primary_button = {
  name: "View training providers on map",
  link: "/training/map",
};

Index.layout = (page) => (
  <Layout
    children={page}
    title="Training Providers"
    primary_button={primary_button}
  />
);

export default Index;
