export const SelectStyles = {
  menuPortal: (base) => ({
    ...base,
    fontSize: "14px",
    lineHeight: "20px",
  }),
  option: (base, state) => ({
    ...base,
    fontSize: "14px",
    backgroundColor: state.isSelected ? "#f97316" : null,
    color: state.isSelected ? "white" : null,
    "&:hover": {
      borderColor: "#f97316",
      backgroundColor: "#fdba74",
    },
  }),
  control: (base, state) => ({
    ...base,
    fontSize: "14px",
    borderColor: state.isFocused ? "#f97316" : "rgb(209 213 219)",
    // outline: state.isFocused ? "2px solid #0000" : null,
    // outlineOffset: state.isFocused ? "2px" : null,
    boxShadow: state.isFocused
      ? "0px 0px 0px 1px rgba(249,115,22,1)"
      : "0 1px 2px 0 rgba(0, 0, 0, 0.05)",

    borderRadius: "6px",
    "&:hover": {
      borderColor: state.isFocused ? "#f97316" : "rgb(209 213 219)",
      //   boxShadow: "0px 0px 0px 1px rgba(249,115,22,1)",
    },
  }),
  input: (base) => ({
    ...base,
    fontSize: "14px",
    width: "100%",
    lineHeight: "20px",
    "input:focus": {
      boxShadow: "none",
    },
  }),
};
