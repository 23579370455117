import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

export const Subscription = ({ general }) => {
    const [cancelSubscription, setCancelSubscription] = useState(false);
    const customer_id = general?.customer_id ? general?.customer_id : "-";
    const subscription_id = general?.subscription_id
        ? general?.subscription_id
        : "-";
    const plan_name = general?.plan_name
        ? general?.plan_name.toUpperCase().replace(/_/g, " ")
        : "Not Selected Any Plan";
    const plan_name_css = general?.plan_name
        ? "bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400"
        : "bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400";
    const plan_status = general?.plan_status
        ? general?.plan_status.toUpperCase()
        : "Not Activated";
    const plan_status_css = general?.plan_status
        ? "bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400"
        : "bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400";

    const current_period_start =
        general?.plan_status == "trialing"
            ? new Date(general?.trial_start).toDateString()
            : general?.plan_status == "paid" || general?.plan_status == "active"
            ? new Date(general?.current_period_start).toDateString()
            : "-";
    const current_period_end =
        general?.plan_status == "trialing"
            ? new Date(general?.trial_end).toDateString()
            : general?.plan_status == "paid" || general?.plan_status == "active"
            ? new Date(general?.current_period_end).toDateString()
            : "-";

    const handleCancelSubscription = () => {
        const text =
            general?.plan_status == "trialing"
                ? "Are you sure you want to cancel your subscription? Your free trial version is currently in use."
                : general?.plan_status == "paid" ||
                  general?.plan_status == "active"
                ? "Are you sure you want to cancel your subscription? There will not be a refund given to you."
                : "This will cancel the subscription.";
        Swal.fire({
            title: "Are you sure?",
            text: text,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#d6d6d6",
            confirmButtonText: "Yes, cancel it!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                if (general?.subscription_id) {
                    axios
                        .post("/subscriptions/cencelsubscription", {
                            subscription_id: general?.subscription_id,
                        })
                        .then((res) => {
                            console.log(res);
                            if (res?.status === 200 && res?.data?.status) {
                                Swal.fire(
                                    "Cancelled!",
                                    "The subscription has been canceled.",
                                    "success"
                                );
                                setTimeout(() => {
                                    setCancelSubscription(false);
                                    window.location.href = "/subscriptions";
                                }, 3000);
                            } else {
                                setCancelSubscription(false);
                                Swal.fire("Error", res?.data?.message, "error");
                            }
                        })
                        .catch((error) => {
                            setCancelSubscription(false);
                            Swal.fire("Error", error.message.message, "error");
                        });
                } else {
                    setCancelSubscription(false);
                    Swal.fire(
                        "Error",
                        "Subscription Id is not found.",
                        "error"
                    );
                }
            }
        });
    };

    return (
        <>
            <div className="mt-10 divide-y divide-gray-200">
                <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                        User Subscriptions
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                        User Subscriptions information about your profile.
                    </p>
                </div>
                <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <label
                                htmlFor="name"
                                className="text-sm font-medium text-gray-500"
                            >
                                Customer ID
                            </label>
                            <div className="flex max-w-lg rounded-md">
                                <p>
                                    <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                        {customer_id}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </dl>
                </div>
                <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <label
                                htmlFor="name"
                                className="text-sm font-medium text-gray-500"
                            >
                                Subscription ID
                            </label>
                            <div className="flex max-w-lg rounded-md">
                                <p>
                                    <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                        {subscription_id}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </dl>
                </div>
                <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <label
                                htmlFor="name"
                                className="text-sm font-medium text-gray-500"
                            >
                                Plan Name
                            </label>
                            <div className="flex max-w-lg rounded-md">
                                <p>
                                    <span className={plan_name_css}>
                                        {plan_name}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </dl>
                </div>
                <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <label
                                htmlFor="name"
                                className="text-sm font-medium text-gray-500"
                            >
                                Plan Status
                            </label>
                            <div className="flex max-w-lg rounded-md">
                                <p>
                                    <span className={plan_status_css}>
                                        {plan_status}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </dl>
                </div>
                <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <label
                                htmlFor="name"
                                className="text-sm font-medium text-gray-500"
                            >
                                Subscription Started
                            </label>
                            <div className="flex max-w-lg rounded-md">
                                <p>
                                    <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                        {current_period_start}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </dl>
                </div>
                <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <label
                                htmlFor="name"
                                className="text-sm font-medium text-gray-500"
                            >
                                Subscription Ended
                            </label>
                            <div className="flex max-w-lg rounded-md">
                                <p>
                                    <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                        {current_period_end}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </dl>
                </div>
                {/* {general?.customer_id && general?.subscription_id && general?.plan_status == 'trialing' && general?.trial_start && general?.trial_end ? 
        <div className="pt-5">
          <div className="flex justify-center">
            <button
              type="submit"
              disabled={cancelSubscription}
              className={`${cancelSubscription ? 'cursor-not-allowed' : ''} ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
              onClick={() => {
                handleCancelSubscription()
                setCancelSubscription(true)
              }}
            >
              Cancel Subscription
            </button>
          </div>
        </div>
        : null} */}
                {!general?.customer_id &&
                !general?.subscription_id &&
                (!general?.plan_status == "paid" ||
                    !general?.plan_status == "active") ? (
                    <div className="pt-5">
                        <div className="flex justify-center">
                            <a
                                href={"/subscriptions"}
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                            >
                                Active Subscription
                            </a>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default Subscription;
